<form [formGroup]="contractForm">
  <div class="form-section">
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="duration" class="form-label">{{
            "forms.inputs.duration" | translate
            }}</label>
          <select id="duration" class="form-control" formControlName="duration" (change)="calculateKms()">
            <option value="">
              {{ "forms.inputs.duration" | translate }}
            </option>
            <option value="12">
              12 {{ "forms.inputs.month" | translate }}
            </option>
            <option value="24">
              24 {{ "forms.inputs.month" | translate }}
            </option>
            <option value="36">
              36 {{ "forms.inputs.month" | translate }}
            </option>
            <option value="48">
              48 {{ "forms.inputs.month" | translate }}
            </option>
            <option value="60">
              60 {{ "forms.inputs.month" | translate }}
            </option>
            <option value="72">
              72 {{ "forms.inputs.month" | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="type" class="form-label">{{
            "forms.inputs.type" | translate
            }}</label>
          <select id="type" class="form-control" formControlName="type">
            <option *ngFor="let type of type_" [value]="type">
              {{ "forms.inputs." + type | translate }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="yearly_kms" class="form-label">{{
            "forms.inputs.yearly_kms" | translate
            }}</label>
          <input type="text" class="form-control" id="yearly_kms" required formControlName="yearly_kms" placeholder="{{
              'forms.inputs.yearly_kms' | translate
            }}" />
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="duration" class="form-label">{{
            "forms.inputs.include" | translate
            }}</label>
          <div class="row">
            <div class="col-12">
              <div class="form-check require-action">
                <input class="form-check-input" type="checkbox" formControlName="include_insurance">
                <label class="form-check-label" for="include_insurance">
                  {{ "forms.inputs.insurance" | translate }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-check require-action">
                <input class="form-check-input" type="checkbox" formControlName="include_maintenance">
                <label class="form-check-label" for="include_maintenance">
                  {{ "forms.inputs.maintance" | translate }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-check require-action">
                <input class="form-check-input" type="checkbox" formControlName="include_tires">
                <label class="form-check-label" for="include_tires">
                  {{ "forms.inputs.tires" | translate }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-check require-action">
                <input class="form-check-input" type="checkbox" formControlName="include_replacement_vehicle">
                <label class="form-check-label" for="include_tires">
                  {{ "forms.inputs.include_replacement_vehicle" | translate }}
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="total_kms" class="form-label">{{
            "forms.inputs.total_kms" | translate
            }}</label>
          <input type="text" class="form-control" id="total_kms" required formControlName="total_kms" placeholder="{{
              'forms.inputs.total_kms' | translate
            }}" />
        </div>
      </div>
    </div>

    <div class="row linea"></div>
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="initial_payment" class="form-label">{{
            "forms.inputs.initial_payment" | translate
            }}</label>
          <div class="currencydiv">
            <input type="text" class="form-control" id="initial_payment" required formControlName="initial_payment"
              placeholder="{{
                'forms.inputs.initial_payment' | translate
              }}" />
            <span> EUR</span>
          </div>
        </div>
      </div>
      <div class="col-4 d-flex align-items-center">
        <div class="form-check require-action">
          <input class="form-check-input" type="checkbox" formControlName="initial_payment_paid">
          <label class="form-check-label" for="initial_payment_paid">
            {{ "forms.inputs.initial_payment_paid" | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="deposit" class="form-label">{{
            "forms.inputs.deposit" | translate
            }}</label>
          <div class="currencydiv">
            <input type="text" class="form-control" id="deposit" required formControlName="deposit"
              placeholder="{{
                'forms.inputs.deposit' | translate
              }}" />
            <span> EUR</span>
          </div>
        </div>
      </div>
      <div class="col-4 d-flex align-items-center">
        <div class="form-check require-action ">
          <input class="form-check-input" type="checkbox" formControlName="deposit_paid">
          <label class="form-check-label" for="deposit_paid">
            {{ "forms.inputs.deposit_paid" | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label for="monthly_fee_base" class="form-label">{{
          "forms.inputs.monthly_fee" | translate
          }}</label>
        <div class="row">
          <div class="col-4">

            <div class="mb-3">

              <div class="currencydiv">
                <input type="text" class="form-control" id="monthly_fee_base" required
                  formControlName="monthly_fee_base" placeholder="{{
                  'forms.inputs.monthly_fee_base' | translate
                }}" />
                <span> +</span>
              </div>
              <label for="monthly_fee_tax" class="form-label text08">{{
                "forms.inputs.base" | translate
                }}</label>
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <div class="currencydiv">
                <input type="text" class="form-control" id="monthly_fee_tax" required formControlName="monthly_fee_tax"
                  placeholder="{{
                  'forms.inputs.monthly_fee_tax' | translate
                }}" />
                <span>=</span>
              </div>
              <label for="monthly_fee_tax" class="form-label text08">{{
                "forms.inputs.tax" | translate
                }}</label>
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <div class="currencydiv">
                <input type="text" class="form-control" id="monthly_fee_total" required
                  formControlName="monthly_fee_total" placeholder="{{
                  'forms.inputs.monthly_fee_total' | translate
                }}" />
                <span>EUR</span>
              </div>
              <label for="monthly_fee_tax" class="form-label text08">{{
                "forms.inputs.total" | translate
                }}</label>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
