import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, Location } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Component, Output, EventEmitter, Input, SimpleChanges, ViewChild, ElementRef, } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { AlertService } from '../../../../core/services/alert/alert.service';
import { InvoiceService } from '../../../../core/services/invoice/invoice.service';
import { LocalStorageService } from '../../../../core/services/localStorage/local-storage.service';
import { FormAddOrdersComponent } from './form-add-orders/form-add-orders.component';
import { OrdersService } from '../../../../core/services/orders/orders.service';


@Component({
  selector: 'app-orders-form',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    NgSelectModule,
    TranslateModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormAddOrdersComponent],
  templateUrl: './orders-form.component.html',
  styleUrl: './orders-form.component.css'
})
export class OrdersFormComponent {
  @Input() invoice_reference?: any;
  @Output() updatedOrders = new EventEmitter<boolean>();
  @Output() openFormAddOrders = new EventEmitter<any>();
  @Input() invoiceData: any = [];

  data: any[] = [];
  form: FormGroup = this.fb.group({});

  totalComision: number = 0;
  showScrollIndicator: boolean = false; // Estado para mostrar la flecha

  @ViewChild('scrollableTable') scrollableTable?: ElementRef;


  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private invoiceService: InvoiceService
  ) {

  }

  ngOnInit() {

    // Mostrar la flecha solo si hay más de 12 elementos
    if (this.data.length > 12) {
      this.showScrollIndicator = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['invoiceData']) {
        this.data = this.invoiceData.orders;
        console.log("🚀 ~ OrdersFormComponent ~ ngOnChanges ~ this.data:", this.data)
        this.totalComision = this.data.reduce((sum, item) => sum + item.broker_commission_total, 0);

      }
    }
  }

  // Chequear si se puede hacer scroll hacia abajo
  checkScroll(event: any) {
    const element = event.target;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    // Si no está en la parte inferior y hay más de 12 elementos, mostrar el indicador
    if (this.data.length > 12) {
      this.showScrollIndicator = !atBottom;
    }
  }

  // Método para eliminar un elemento
  deleteItem(item:any) {
    this.data = this.data.filter(i => i !== item);
    this.totalComision = this.data.reduce((sum, i) => sum + i.commission, 0); // Recalcular total

    // Verificar si hay más de 12 elementos después de eliminar
    if (this.data.length > 12) {
      this.showScrollIndicator = true;
    } else {
      this.showScrollIndicator = false;
    }
  }

  OpenFormAddOrders() {
    this.openFormAddOrders.emit();
  }

  getBadgeColor(stage: string, priority: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
      invoice: 'text-no-action'
    };

    const priorityColor = statusColorMap[priority];
    return priorityColor || 'text-priority-0';
  }


  selectedItems: any[] = []; // Array para almacenar los elementos seleccionados

  toggleSelection(item: any): void {
    const index = this.selectedItems.findIndex(selectedItem => selectedItem === item.reference);
    if (index === -1) {
      this.selectedItems.push(item.reference); // Añadir si no está seleccionado
    } else {
      this.selectedItems.splice(index, 1); // Remover si ya está seleccionado
    }
  }

  isSelected(item: any): boolean {
    return this.selectedItems.some(selectedItem => selectedItem.reference === item.reference);
  }


  // Función para determinar si todos los items están seleccionados
  areAllSelected(): boolean {
    return this.data.length > 0 && this.selectedItems.length === this.data.length;
  }

  // Función para seleccionar/deseleccionar todos los items
  toggleSelectAll(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      this.selectedItems = [...this.data]; // Seleccionar todos los elementos
    } else {
      this.selectedItems = []; // Deseleccionar todos los elementos
    }
  }
  emitUpdatedOrders(){
    this.updatedOrders.emit(true);
  }

  onSubmit() {
    let formResult = this.form.value;

    const updateOrders = this.data
    .filter(item => ! this.selectedItems.includes(item.reference))
    .map(item => item.reference);

    formResult.orders = updateOrders;

    this.alertService.loading('swal_messages.please_wait');
    this.invoiceService.updateData(this.invoiceData.reference,formResult).subscribe({
      next: (data) => {
        this.alertService
          .successOk('swal_messages.updated_invoice')
          .then((result) => {
            if (result.isConfirmed) {
              this.selectedItems = [];
              this.emitUpdatedOrders();
            }
          });
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      },
    });

  }
}
