<div>
  <!--   <div class="start-request" *ngIf="!widgetUrl">
    <button (click)="requestClavePin()">Iniciar solicitud</button>
  </div>

  <div class="main-url" *ngIf="widgetUrl">
    <label for="name" class="form-label">URL generada:</label>
    <div class="input-group div-copy">
      <input id="widgetUrl" class="form-control" type="text" [value]="widgetUrl" readonly />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary copy" type="button" (click)="copyToClipboard()">
          <i class="icon-copy"></i>
        </button>
      </div>
    </div>
    <button class="btn btn-secondary mt-2" (click)="requestClavePin()">Generar nueva solicitud</button>
  </div> -->

  <div *ngIf="isLoading" class="loader-container">
    <div class="loader"></div>
  </div>

  <div *ngIf="!isLoading">
    <div class="start-request" *ngIf="!widgetUrl">
      <button (click)="requestClavePin()">Iniciar solicitud</button>
    </div>

    <div class="main-url" *ngIf="widgetUrl">
      <label for="name" class="form-label">URL generada:</label>
      <div class="input-group div-copy">
        <input id="widgetUrl" class="form-control" type="text" [value]="widgetUrl" readonly />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button" (click)="copyToClipboard()">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <button class="btn btn-secondary mt-2" (click)="requestClavePin()">Generar nueva solicitud</button>
    </div>
  </div>


  <!-- Mostrar los datos financieros -->
  <div *ngIf="financialData" class="financial-data mt-4">
    <!-- Address -->
    <div class="section" style="cursor: pointer;">
      <h5>Año {{financialData?.data?.aeat.year}}</h5>
    </div>

    <div class="accordion">
      <form [formGroup]="financialDataForm">

        <div class="accordion-item">
          <div class="accordion-header" (click)="toggleSection('address')">
            <label class="form-label">{{"forms.inputs.financial-data.address.address" | translate}}</label>

            <div style="cursor: pointer">
              <i class="" [ngClass]="{
                    'icon-chevron-up': sections['address'],
                    'icon-chevron-down': !sections['address']
                  }"></i>
            </div>
          </div>

          <div class="accordion-body" *ngIf="sections['address']">
            <div class="mb-3">
              <label for="roadType" class="form-label">{{"forms.inputs.financial-data.address.road_type" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="road_type" readonly />
            </div>

            <div class="mb-3">
              <label for="roadName" class="form-label">{{"forms.inputs.financial-data.address.road_name" |
                translate}}:</label>
              <input id="roadName" class="form-control" type="text" formControlName="road_name" readonly />
            </div>

            <div class="mb-3">
              <label for="number" class="form-label">{{"forms.inputs.financial-data.address.number" |
                translate}}:</label>
              <input id="number" class="form-control" type="text" formControlName="number" readonly />
            </div>

            <div class="mb-3">
              <label for="postalCode" class="form-label">{{"forms.inputs.financial-data.address.postal_code" |
                translate}}:</label>
              <input id="postalCode" class="form-control" type="text" formControlName="postal_code" readonly />
            </div>

            <div class="mb-3">
              <label for="municipality" class="form-label">{{"forms.inputs.financial-data.address.municipality" |
                translate}}:</label>
              <input id="municipality" class="form-control" type="text" formControlName="municipality" readonly />
            </div>

            <div class="mb-3">
              <label for="province" class="form-label">{{"forms.inputs.financial-data.address.province" |
                translate}}:</label>
              <input id="province" class="form-control" type="text" formControlName="province" readonly />
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="financialDataForm2">

        <div class="accordion-item">
          <div class="accordion-header" (click)="toggleSection('earned_bank_accounts')">
            <label class="form-label"> {{"forms.inputs.financial-data.earned_bank_accounts.earned_bank_accounts" |
              translate }}
            </label>
            <div style="cursor: pointer">
              <i class="" [ngClass]="{
                                      'icon-chevron-up': sections['earned_bank_accounts'],
                                      'icon-chevron-down': !sections['earned_bank_accounts']
                                    }"></i>
            </div>
          </div>
          <div class="accordion-body" *ngIf="sections['earned_bank_accounts']">
            <div class="mb-3">
              <label for="roadType" class="form-label">{{"forms.inputs.financial-data.earned_bank_accounts.code" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="codigo" readonly />
            </div>

            <div class="mb-3">
              <label for="number" class="form-label">{{"forms.inputs.financial-data.earned_bank_accounts.account" |
                translate}}:</label>
              <input id="number" class="form-control" type="text" formControlName="cuenta" readonly />
            </div>

            <div class="mb-3">
              <label for="postalCode"
                class="form-label">{{"forms.inputs.financial-data.earned_bank_accounts.account_income" |
                translate}}:</label>
              <input id="postalCode" class="form-control" type="text" formControlName="ingresos_cuenta" readonly />
            </div>

            <div class="mb-3">
              <label for="municipality"
                class="form-label">{{"forms.inputs.financial-data.earned_bank_accounts.payer_nif" |
                translate}}:</label>
              <input id="municipality" class="form-control" type="text" formControlName="nif_pagador" readonly />
            </div>

            <div class="mb-3">
              <label for="province" class="form-label">{{"forms.inputs.financial-data.earned_bank_accounts.payer_name" |
                translate}}:</label>
              <input id="province" class="form-control" type="text" formControlName="nombre_pagador" readonly />
            </div>

            <div class="mb-3">
              <label for="province" class="form-label">{{"forms.inputs.financial-data.earned_bank_accounts.type" |
                translate}}:</label>
              <input id="province" class="form-control" type="text" formControlName="tipo" readonly />
            </div>
            <div class="mb-3">
              <label for="province" class="form-label">{{"forms.inputs.financial-data.earned_bank_accounts.holders" |
                translate}}:</label>
              <input id="province" class="form-control" type="text" formControlName="titulares" readonly />
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="earned_income">

        <div class="accordion-item">
          <div class="accordion-header" (click)="toggleSection('earned_income')">
            <label class="form-label">
              {{"forms.inputs.financial-data.earned_income.earned_income" | translate }}
            </label>
            <div style="cursor: pointer">
              <i class="" [ngClass]="{
                              'icon-chevron-up': sections['earned_income'],
                              'icon-chevron-down': !sections['earned_income']
                            }"></i>
            </div>
          </div>

          <div class="accordion-body" *ngIf="sections['earned_income']">

            <div class="mb-3">
              <label for="clave" class="form-label">
                {{"forms.inputs.financial-data.earned_income.clave" | translate}}:
              </label>
              <input id="clave" class="form-control" type="text" formControlName="clave" readonly />
            </div>

            <div class="mb-3">
              <label for="codigo" class="form-label">
                {{"forms.inputs.financial-data.earned_income.codigo" | translate}}:
              </label>
              <input id="codigo" class="form-control" type="text" formControlName="codigo" readonly />
            </div>

            <div class="mb-3">
              <label for="gastosDeducibles" class="form-label">
                {{"forms.inputs.financial-data.earned_income.gastos_deducibles" | translate}}:
              </label>
              <input id="gastosDeducibles" class="form-control" type="number" formControlName="gastos_deducibles"
                readonly />
            </div>

            <div class="mb-3">
              <label for="ingresosCuenta" class="form-label">
                {{"forms.inputs.financial-data.earned_income.ingresos_cuenta" | translate}}:
              </label>
              <input id="ingresosCuenta" class="form-control" type="number" formControlName="ingresos_cuenta"
                readonly />
            </div>

            <div class="mb-3">
              <label for="ingresosCuentaRepercutidos" class="form-label">
                {{"forms.inputs.financial-data.earned_income.ingresos_cuenta_repercutidos" | translate}}:
              </label>
              <input id="ingresosCuentaRepercutidos" class="form-control" type="number"
                formControlName="ingresos_cuenta_repercutidos" readonly />
            </div>

            <div class="mb-3">
              <label for="nifPagador" class="form-label">
                {{"forms.inputs.financial-data.earned_income.nif_pagador" | translate}}:
              </label>
              <input id="nifPagador" class="form-control" type="text" formControlName="nif_pagador" readonly />
            </div>

            <div class="mb-3">
              <label for="nombrePagador" class="form-label">
                {{"forms.inputs.financial-data.earned_income.nombre_pagador" | translate}}:
              </label>
              <input id="nombrePagador" class="form-control" type="text" formControlName="nombre_pagador" readonly />
            </div>

            <div class="mb-3">
              <label for="reduccion" class="form-label">
                {{"forms.inputs.financial-data.earned_income.reduccion" | translate}}:
              </label>
              <input id="reduccion" class="form-control" type="number" formControlName="reduccion" readonly />
            </div>

            <div class="mb-3">
              <label for="retenciones" class="form-label">
                {{"forms.inputs.financial-data.earned_income.retenciones" | translate}}:
              </label>
              <input id="retenciones" class="form-control" type="number" formControlName="retenciones" readonly />
            </div>

            <div class="mb-3">
              <label for="retencionesIncapacidad" class="form-label">
                {{"forms.inputs.financial-data.earned_income.retenciones_retribuciones_incapacidad_laboral" |
                translate}}:
              </label>
              <input id="retencionesIncapacidad" class="form-control" type="number"
                formControlName="retenciones_retribuciones_incapacidad_laboral" readonly />
            </div>

            <div class="mb-3">
              <label for="retribucionesDinerarias" class="form-label">
                {{"forms.inputs.financial-data.earned_income.retribuciones_dinerarias" | translate}}:
              </label>
              <input id="retribucionesDinerarias" class="form-control" type="number"
                formControlName="retribuciones_dinerarias" readonly />
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="loans_credits_real_estate">
        <div class="accordion-item">
          <div class="accordion-header" (click)="toggleSection('loans_credits_real_estate')">
            <label class="form-label">
              {{"forms.inputs.financial-data.loans_credits_real_estate.loans_credits_real_estate" |
              translate }}
            </label>
            <div style="cursor: pointer">
              <i class="" [ngClass]="{
                                              'icon-chevron-up': sections['loans_credits_real_estate'],
                                              'icon-chevron-down': !sections['loans_credits_real_estate']
                                            }"></i>
            </div>
          </div>
          <div class="accordion-body" *ngIf="sections['loans_credits_real_estate']">
            <div class="mb-3">
              <label for="capital_amortizado"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.capital_amortizado" |
                translate}}:</label>
              <input id="capital_amortizado" class="form-control" type="text" formControlName="capital_amortizado"
                readonly />
            </div>

            <div class="mb-3">
              <label for="roadType" class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.codigo"
                |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="codigo" readonly />
            </div>


            <div class="mb-3">
              <label for="roadType"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.fecha_constitucion_prestamo"
                |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="fecha_constitucion_prestamo"
                readonly />
            </div>

            <div class="mb-3">
              <label for="roadType"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.gastos_financieros" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="gastos_financieros" readonly />
            </div>

            <div class="mb-3">
              <label for="roadType"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.identificacion_prestamo_credito"
                |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="identificacion_prestamo_credito"
                readonly />
            </div>

            <div class="mb-3">
              <label for="roadType"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.intereses" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="intereses" readonly />
            </div>

            <div class="mb-3">
              <label for="roadType"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.nif_declarante" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="nif_declarante" readonly />
            </div>

            <div class="mb-3">
              <label for="roadType"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.nombre_declarante" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="nombre_declarante" readonly />
            </div>

            <div class="mb-3">
              <label for="roadType"
                class="form-label">{{"forms.inputs.financial-data.loans_credits_real_estate.porcentaje_participacion" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="porcentaje_participacion"
                readonly />
            </div>

          </div>
        </div>
      </form>
      <form [formGroup]="social_security">

        <div class="accordion-item">
          <div class="accordion-header" (click)="toggleSection('social_security')">
            <label class="form-label"> {{"forms.inputs.financial-data.social_security.social_security" |
              translate }}
            </label>
            <div style="cursor: pointer">
              <i class="" [ngClass]="{
                                      'icon-chevron-up': sections['social_security'],
                                      'icon-chevron-down': !sections['social_security']
                                    }"></i>
            </div>
          </div>
          <div class="accordion-body" *ngIf="sections['social_security']">
            <div class="mb-3">
              <label for="roadType" class="form-label">{{"forms.inputs.financial-data.social_security.postal_code" |
                translate}}:</label>
              <input id="roadType" class="form-control" type="text" formControlName="postal_code" readonly />
            </div>
          </div>
        </div>
      </form>


    </div>

  </div>