import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage/local-storage.service';
import { AlertService } from '../services/alert/alert.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.localStorageService.getItem('access_token');
    if (accessToken) {
      // Clonar la solicitud y añadir el header de autorización
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              return this.handleUnAuthorizedError(request, next);
            case 404:
              return this.handle404Error(request, next, err);
            case 422:
              return this.handle422Error(request, next, err);
            case 429:
              return this.handle429Error(request, next, err);
            case 400:
              return this.handle400Error(request, next, err);
            case 500:
              return this.handle500Error(request, next, err);
            default:
              return throwError(err);
              break;
          }
        }
        // Si no es un HttpErrorResponse, simplemente re-lanzar el error
        return throwError(err);
      })
    );
  }

  handle400Error(request: HttpRequest<any>, next: HttpHandler, error: any) {
    const customError = {
      code: 400,
      message: 'Recurso no encontrado',
      originalError: error,
      error: error.error.errors,
    };
    return throwError(customError);
  }

  handle500Error(request: HttpRequest<any>, next: HttpHandler, error: any) {
    const customError = {
      code: 500,
      message: 'Recurso no encontrado',
      originalError: error,
      error: error.error.errors,
    };
    return throwError(customError);
  }

  handle422Error(request: HttpRequest<any>, next: HttpHandler, error: any) {
    const customError = {
      code: 422,
      message: 'Recurso no encontrado',
      originalError: error,
      error: error.error.errors,
    };
    return throwError(customError);
  }
  handle429Error(request: HttpRequest<any>, next: HttpHandler, error: any) {
    const customError = {
      code: 429,
      message: 'Recurso no encontrado',
      originalError: error,
      error: "swal_messages.general.error"
    };
    return throwError(customError);
  }

  handle404Error(request: HttpRequest<any>, next: HttpHandler, error: any) {
    const customError = {
      code: 404,
      message: 'Recurso no encontrado',
      originalError: error,
      error: error.error.errors,
    };
    return throwError(customError);
  }

  handleUnAuthorizedError(request: HttpRequest<any>, next: HttpHandler) {
    this.localStorageService.reset();
    window.location.reload();
    return throwError(() => {});
  }
}
