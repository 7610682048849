<div>
  <div class="mb-3 buscador">
    <div class="input-group form-control">
      <i class="icon-magnifier" aria-hidden="true"></i>
      <input [(ngModel)]="searchText" type="text" placeholder="{{ 'buttons.orders-filter' | translate }}"
        aria-label="Buscar"  #searchBox matInput (keyup)="keyup($event)" class="placeholder-filter text" />
    </div>

    <div>
      <button class="btn btn-hover filter" type="button" (click)="toggleDropdown()">
        {{ filterButtonLabel }}
        <i class="icon-equalizer"></i>
        <!-- <i class="fas fa-filter" aria-hidden="true"></i> -->
      </button>

      <div *ngIf="showDropdown" class="dropdown-content" role="menu" (mouseenter)="cancelCloseTimeout()"
        (mouseleave)="startCloseTimeout()">
        <div class="filter-content">
          <div class="clear-filter mb-3">
            <div>{{ "buttons.filter" | translate }}</div>
          </div>

          <!-- FILTER -->

          <!-- <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleStage }">
                <input class="text" #stageInput type="text" (focus)="onFocus('stage')" (blur)="hideOptions()"
                  [(ngModel)]="stageSearchTerm" placeholder="{{ 'filter.stage' | translate }}" (input)="searchStages(stageSearchTerm)"
                  [value]="displayTextStage" />
                <span *ngIf="clearableStage" class="clear-icon" (click)="
                    clearSelectedOption('stage'); $event.stopPropagation()
                  "><i class="icon-cross2"></i></span>

                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleStage" [ngClass]="{ visible: optionsVisibleStage }">
                <div class="option-value" *ngFor="let stage of filteredStages"
                  (mousedown)="onSelectStage(stage, stageInput)">
                  <div>{{ "forms.inputs.status_select."+stage.key | translate}}</div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleStatus }">
                <input class="text" #statusInput type="text" (focus)="onFocus('status')" (blur)="hideOptions()"
                  [(ngModel)]="statusSearchTerm" placeholder="{{ 'filter.status' | translate }}"
                  [value]="displayTextStatus" />
                <span *ngIf="clearableStatus" class="clear-icon" (click)="
                    clearSelectedOption('status'); $event.stopPropagation()
                  "><i class="icon-cross2"></i></span>

                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleStatus" [ngClass]="{ visible: optionsVisibleStatus }">
                <div class="option-value" *ngFor="let status of filteredStatus"
                  (mousedown)="onSelectStatus(status, statusInput)">
                  <div>{{ "forms.inputs.status_select."+status.key | translate}}</div>
                </div>
              </div>
            </div>
          </div>

          <!--    <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{'expanded': optionsVisibleClient}">
                <input class="text" #clientInput type="text"(focus)="onFocus('client')" (blur)="hideOptions()"
                  [(ngModel)]="clientSearchTerm" placeholder="{{ 'forms.inputs.client' | translate }}"
                  (input)="searchClients(clientSearchTerm)" [value]="displayTextClient" />
                <span *ngIf="clearableClient" class="clear-icon"
                  (click)="clearSelectedOption('client'); $event.stopPropagation();"><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleClient" [ngClass]="{ visible: optionsVisibleClient }">
                <div class="option-value" *ngFor="let client of filteredClients"
                  (mousedown)="onSelectClient(client, clientInput)">
                  <div>
                    {{ client.name }}
                    {{ client.lastname ? client.lastname : "" }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="mb-3" *ngIf="accountType !== 'broker'">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleBroker }">
                <input class="text" #brokerInput type="text" (focus)="onFocus('broker')" (blur)="hideOptions()"
                  [(ngModel)]="brokerSearchTerm" (input)="searchBroker(brokerSearchTerm)"
                  placeholder="{{ 'forms.inputs.broker' | translate }}" [value]="displayTextBroker" />
                <span *ngIf="clearableBroker" class="clear-icon" (click)="
                    clearSelectedOption('broker'); $event.stopPropagation()
                  "><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleBroker" [ngClass]="{ visible: optionsVisibleBroker }">
                <div *ngFor="let broker of brokerAccounts" class="option-value" (mousedown)="
                    onSelectBroker(broker,brokerInput)">
                  <div>{{ broker.name }}</div>
                </div>
              </div>
            </div>


          </div>

          <div class="mb-3" *ngIf="accountType !== 'supplier'">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleSupplier }">
                <input class="text" #supplierInput type="text" (focus)="onFocus('supplier')" (blur)="hideOptions()"
                  [(ngModel)]="supplierSearchTerm" (input)="searchSupplier(supplierSearchTerm)"
                  placeholder="{{ 'forms.inputs.supplier' | translate }}" [value]="displayTextSupplier" />
                <span *ngIf="clearableSupplier" class="clear-icon" (click)="
                    clearSelectedOption('supplier'); $event.stopPropagation()
                  "><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleSupplier"
                [ngClass]="{ visible: optionsVisibleSupplier }">
                <div *ngFor="
                    let supplier of supplierAccounts
                  " class="option-value" (mousedown)="onSelectSupplier(supplier, supplierInput)">
                  <div>{{ supplier.name }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3" *ngIf="accountType !== 'vendor'">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleVendor }">
                <input class="text" #vendorInput type="text" (focus)="onFocus('vendor')" (blur)="hideOptions()"
                  [(ngModel)]="vendorSearchTerm" (input)="searchVendor(vendorSearchTerm)"
                  placeholder="{{ 'forms.inputs.vendor' | translate }}" [value]="displayTextVendor" />
                <span *ngIf="clearableVendor" class="clear-icon" (click)="
                    clearSelectedOption('vendor'); $event.stopPropagation()
                  "><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleVendor" [ngClass]="{ visible: optionsVisibleVendor }">
                <div *ngFor="let vendor of vendorAccounts" class="option-value" (mousedown)="
                    onSelectVendor(vendor,vendorInput)">
                  <div>{{ vendor.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleAgent }">
                <input class="text" #agentInput type="text" (focus)="onFocus('agent')" (blur)="hideOptions()"
                  [(ngModel)]="agentSearchTerm" (input)="searchAgent(agentSearchTerm)"
                  placeholder="{{ 'forms.inputs.agent' | translate }}" [value]="displayTextAgent" />
                <span *ngIf="clearableAgent" class="clear-icon" (click)="
                  clearSelectedOption('agent'); $event.stopPropagation()
                "><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleAgent" [ngClass]="{ visible: optionsVisibleAgent }">
                <div *ngFor="let agent of agentAccounts" class="option-value" (mousedown)="
                  onSelectAgent(agent,agentInput)">
                  <div>{{ agent.name }} {{agent.lastname}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleBrand }">
                <input class="text" #brandInput type="text" (focus)="onFocus('brand')" (blur)="hideOptions()"
                  [(ngModel)]="brandSearchTerm" placeholder="{{ 'forms.inputs.vehicle_brand' | translate }}"
                  (input)="searchBrands(brandSearchTerm)" [value]="displayTextBrand" />
                <span *ngIf="clearableBrand" class="clear-icon" (click)="
                    clearSelectedOption('brand'); $event.stopPropagation()
                  "><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleBrand" [ngClass]="{ visible: optionsVisibleBrand }">
                <div class="option-value" *ngFor="let brand of filteredBrands"
                  (mousedown)="onSelectBrand(brand, brandInput)">
                  <div>{{ brand.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="form-check require-action">
      <input #requireActionCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="onCheckboxChange($event)">
      <label class="form-check-label" for="flexCheckDefault">
        {{ "buttons.require-action" | translate }}
      </label>
   </div>

    <div *ngIf="showClear" class="clear">
      <button class="btn btn-hover clear-button" type="button" (click)="clearFilter($event)">
        {{ "buttons.clearFilter" | translate }}
      </button>
    </div>

  </div>

</div>
