import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormAddOrderComponent } from '../../../pages/orders/form-add-order/form-add-order.component';

@Injectable({
  providedIn: 'root',
})
export class ExtrasService {
  constructor(private translate: TranslateService) {}

  async traducirError2(errores: any) {
    const error = errores;
    var message = '';
    if (typeof error == 'object' && !Array.isArray(error)) {
      for (const key in error) {
        if (typeof error[key] == 'object' && !Array.isArray(error[key])) {
          const keyTranslate = await this.translate
            .get('swal_messages.inputs.' + key)
            .toPromise();
          let valueTranslate = error[key].msg;
          let params = error[key];
          delete params.msg;
          params.campo = keyTranslate;

          valueTranslate = await this.translate
            .get(valueTranslate, params)
            .toPromise();
          if (typeof valueTranslate === 'string') {
            message += '- ' + valueTranslate + '\n';
          }
        }
      }
    }

    if (typeof error == 'object' && Array.isArray(error)) {
      ///array
      for (let i = 0; i < error.length; i++) {
        const item = error[i];
        const aux = await this.translate
          .get('swal_messages.inputs.' + item)
          .toPromise();
        message += '- ' + aux + '\n';
      }
    }

    return message;
  }

  async traducirError(errores: any) {
    const error = errores;
    let message = '';
    for (const key in error) {
      if (Object.hasOwnProperty.call(error, key)) {
        const keyTranslate = await this.translate
          .get('swal_messages.inputs.' + key)
          .toPromise();
  
        let valueTranslate: any = '';
        let params: any = '';
  
        if (typeof error[key] == 'object' && !Array.isArray(error[key])) {
          valueTranslate = error[key].msg;
          params = error[key];
          delete params.msg;
          params.campo = keyTranslate;
        } else if (Array.isArray(error[key])) {
          const translatedErrors = await Promise.all(
            error[key].map(async (err: any) => {
              let localParams = { ...err };
              const localValueTranslate = err['msg'];
              delete localParams.msg;
              localParams.campo = keyTranslate;
  
              const translatedMessage = await this.translate
                .get(localValueTranslate, localParams)
                .toPromise();
  
              return translatedMessage;
            })
          );
          valueTranslate = translatedErrors.join('\n- ');
        }
  
        if (!Array.isArray(error[key])) {
          valueTranslate = await this.translate
            .get(valueTranslate, params)
            .toPromise();
        }
  
        if (typeof valueTranslate === 'string') {
          message += '- ' + valueTranslate + '\n';
        }
      }
    }
    return message;
  }
}
