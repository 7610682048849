import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common'; // Asegúrate de importar CommonModule
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService } from '../../../core/services/alert/alert.service';
import { OrdersService } from '../../../core/services/orders/orders.service';

@Component({
  selector: 'app-form-change-status',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  templateUrl: './form-change-status.component.html',
  styleUrl: './form-change-status.component.css',
})
export class FormChangeStatusComponent implements OnChanges {
  @Output() backButtonClickDocument = new EventEmitter<boolean>();
  @Output() updateStage = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<any>();
  @Input() orderData?: any; // Objeto opc
  @Input() order_reference?: any; // Objeto opcional
  @Input() stage?: any; // Objeto opcional
  @Input() rules?: any; // Objeto opc
  keys = ['stage'];
  form: FormGroup;
  newStage = '';
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private orderService: OrdersService
  ) {
    this.form = this.fb.group({
      stage: ['', Validators.required],
      notes: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['orderData']) {
      if (this.orderData) {
        this.newStage = this.orderData.stage;
        this.checkfields();
      }
    }
  }

  emitBackButtonClick() {
    this.order_reference = null;
    this.backButtonClickDocument.emit(true);
  }

  emitUpdateStage() {
    this.updateStage.emit(true);
  }

  checkfields() {
    this.updateFieldValue();
    this.updateFieldAccessibility();
  }

  updateFieldValue() {
    this.keys.forEach((item) => {
      if (this.form.controls[item]) {
        this.form.controls[item].setValue(this.orderData[item]);
      }
    });
  }

  updateFieldAccessibility() {
    var rules = this.orderData?.rules || {};
    // Deshabilitar todos los campos por defecto
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        this.form.get(field)?.disable();
      }
    }

    // Habilitar solo los campos con valor 'w' en las reglas
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.form.get(field)?.enable();
      }
    }
  }

  onSubmit() {
    this.alertService.loading('swal_messages.updating_order');
    if (this.form.valid) {
      this.orderService
        .updateStatus(this.orderData.reference, this.form.value)
        .subscribe({
          next: (data) => {
            this.emitUpdateStage();
            /* this.alertService
              .successOk('swal_messages.order_status_updated')
              .then((result) => {
                if (result.isConfirmed) {
                  this.emitUpdateStage();
                }
              }); */
          },
          error: (error) => {
            this.alertService.error(error.error).then((result) => {
              if (result.isConfirmed) {
              }
            });
          },
        });
    } else {
      this.alertService.warning('swal_messages.please_complete_form');
      this.alertService.close();
    }
  }

  changeStage(event: any) {
    this.newStage = event;
  }
  
  checkChangeStage() {
    if (this.newStage !== this.orderData.stage) {
      return true;
    }
    return false;
  }
}
