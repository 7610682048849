<form [formGroup]="riskForm">
  <div class="form-section">
    <div class="row d-flex align-items-end">
      <div class="col-12">
        <div class="mb-3">
          <label for="risk_result" class="form-label">{{
            "forms.inputs.risk_result" | translate
            }}</label>
          <select id="risk_result" class="form-control" formControlName="risk_result">
            <option *ngFor="let risk of riskStatus" [value]="risk">
              {{ "forms.inputs." + risk | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <label for="risk_result_notes" class="form-label">{{
            "forms.inputs.risk_result_notes" | translate
            }}</label>
          <textarea class="form-control textarea100" id="risk_result_notes" rows="2" required
            formControlName="risk_result_notes"></textarea>
        </div>
      </div>
    </div>
  </div>
</form>
