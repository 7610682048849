import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { DocumentsService } from '../../../core/services/documents/documents.service';
import { DocumentsTypeService } from '../../../core/services/documentType/documents-type.service';
import { FilterSelectTypesPipe } from '../../../core/pipe/custom-pipes';

interface FormConfig {
  reference: any;
  documents: any[];
  label: string;
  type: string;
  ruleId: string | null;
  documentFunction: (reference: any) => any;
}

interface FileItem {
  file: File;
  selectOption: string;
  key: string;
}

@Component({
  selector: 'app-form-add-document',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgSelectModule,
    FilterSelectTypesPipe,
  ],
  templateUrl: './form-add-document.component.html',
  styleUrl: './form-add-document.component.css',
})
export class FormAddDocumentComponent implements OnChanges {
  @Output() backButtonClickDocument = new EventEmitter<boolean>();
  @Output() addDocuments = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<any>();
  @Output() filesArrayChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() client_reference?: any; // Objeto opcional
  @Input() client?: any; // Objeto opcional
  @Input() activeFormTab?: any; // Objeto opcional
  @Input() selectTypes?: any; // Objeto opcional

  label: string = 'client';
  varSelectType = 'clients';
  uploadFile = true;
  formConfigs: FormConfig[] = [];

  @Input() rules: any = {};
  @Input() clientData?: any;


  existingDocuments: any[] = [];
  showExistingDocuments = true;

  constructor(
    private alertService: AlertService,
    private documentService: DocumentsService,
    private documentTypeService: DocumentsTypeService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeFormTab'] || changes['clienData']) {
      this.existingDocuments = this.clientData.documents;
      this.existingDocuments.sort((a, b) => {
        return a.type.localeCompare(b.type);
      });
      this.showExistingDocuments = this.existingDocuments.length > 0;
      this.varSelectType = this.client.type;
    }
  }



  getDocuments() {
    this.alertService.loading('swal_messages.please_wait');
    this.documentService
      .getClientDocuments(this.clientData.reference)
      .subscribe({
        next: (data: any) => {
          this.existingDocuments = data.data;
          this.existingDocuments.sort((a, b) => {
            return a.type.localeCompare(b.type);
          });
          this.showExistingDocuments = this.existingDocuments.length > 0;
          this.alertService.close();
        },
        error: (error: any) => {
          this.existingDocuments = [];
          this.alertService.close();
        },
      });

  }

  emitBackButtonClick() {
    this.client_reference = null;
    this.files = [];
    this.existingDocuments = [];
    this.filesArrayChanged.emit(this.files);
    this.backButtonClickDocument.emit(true);
  }

  emitAddDocuments() {
    this.addDocuments.emit(true);
  }

  onSubmit() {
    if (!this.client_reference) {
      this.alertService.error('Client reference is missing.');
      return;
    }

    const isValid = this.checkSelectOptionDiversity(this.files);

    if (!isValid) {
      this.alertService.error('swal_messages.document_type_excess');
    } else {
      this.alertService.loading(
        'Por favor espera mientras se añaden los documentos.'
      );
      const formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        formData.append(`file[${i}]`, this.files[i].file);
        formData.append(`type[${i}]`, this.files[i].selectOption);
      }

      formData.append('client_id', this.client_reference);
      this.documentService.addDocument(formData).subscribe({
        next: (data) => {
          this.alertService
            .successOk('Documentos añadidos correctamente.')
            .then((result) => {
              if (result.isConfirmed) {
                this.files = [];
                this.filesArrayChanged.emit(this.files);
                this.getDocuments();
              }
            });
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
            }
          });
        },
      });
    }
  }

  checkSelectOptionDiversity(data: Array<{ file: any; selectOption: string; key: string }>): boolean {
    if (data.length <= 3) {
      return true; // Si hay 3 o menos elementos, no hay restricciones.
    }
    // Contar las ocurrencias de cada `selectOption`.
    const optionCounts: Record<string, number> = {};
    data.forEach((item) => {
      const option = item.selectOption;
      optionCounts[option] = (optionCounts[option] || 0) + 1;
    });

    // Verificar si algún `selectOption` excede el 70 %.
    const total = data.length;
    for (const option in optionCounts) {
      if (optionCounts[option] / total >= 0.7) {
        return false; // Si algún `selectOption` tiene el 70 % o más, no cumple.
      }
    }
    return true; // Si no hay ningún `selectOption` con el 70 % o más, cumple.
  }

  @Output() filesDropped = new EventEmitter<FileList>();
  files: FileItem[] = [];

  actualizarArchivos(nuevosArchivos: any[]) {
    this.files = nuevosArchivos;
    this.filesArrayChanged.emit(this.files);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleHighlight(event, false);

    const newFiles = event.dataTransfer?.files;
    if (newFiles && newFiles.length > 0) {
      for (const file of Array.from(newFiles)) {
        if (
          !this.files.some(
            (f) => f.file.name === file.name && f.file.size === file.size
          )
        ) {
          var selectOption = 'defaultOption';
          this.alertService.loading('swal_messages.please_wait');
          var opt = 'clients';
          this.documentTypeService.decodeType(opt, file.name).subscribe({
            next: (data) => {
              this.alertService.close();
              selectOption = data.data;
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
            error: (error) => {
              this.alertService.close();
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
          });
        }
      }
      this.filesDropped.emit(newFiles);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleHighlight(event, true);
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleHighlight(event, false);
  }

  private toggleHighlight(event: DragEvent, isOver: boolean) {
    const target = event.target as HTMLElement;
    if (target) {
      target.classList.toggle('over', isOver);
    }
  }

  isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  getThumbnailSrc2(name: string): string {
    const patterns = {
      jpg: /\.(jpg|jpeg|png)$/i,
      pdf: /\.pdf$/i,
      doc: /\.(docx?|dotx?)$/i,
      xls: /\.(xls?|xlsx?)$/i,
      zip: /\.zip$/i,
    };

    for (const [key, pattern] of Object.entries(patterns)) {
      if (pattern.test(name)) {
        return `../../../assets/icons/${key}.svg`;
      }
    }

    return '../../../assets/icons/file.svg';
  }

  getThumbnailSrc(file: File): string {
    const patterns = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      pdf: 'application/pdf',
      doc: 'application/msword',
      csv: 'text/csv',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xslx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'word-openxml':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xls: 'application/vnd.ms-excel',
      'xls-openxml':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    for (const [key, mimeType] of Object.entries(patterns)) {
      if (file.type === mimeType) {
        switch (key) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            return `../../../assets/icons/jpg.svg`;
            break;
          case 'pdf':
            return `../../../assets/icons/pdf.svg`;
            break;
          case 'word-openxml':
          case 'doc':
          case 'docx':
            return `../../../assets/icons/doc.svg`;
            break;
          case 'xls-openxml':
          case 'xls':
            return `../../../assets/icons/xls.svg`;
            break;
          default:
            return '../../../assets/icons/file.svg';
            break;
        }
      }
    }
    return '../../../assets/icons/file.svg';
  }



  onFileInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const newFiles = input.files;
    if (newFiles && newFiles.length > 0) {
      for (const file of Array.from(newFiles)) {
        if (
          !this.files.some(
            (f) => f.file.name === file.name && f.file.size === file.size
          )
        ) {
          var selectOption = 'defaultOption';
          this.alertService.loading('swal_messages.please_wait');
          var opt = 'clients';
          this.documentTypeService.decodeType(opt, file.name).subscribe({
            next: (data) => {
              this.alertService.close();
              selectOption = data.data;
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
            error: (error) => {
              this.alertService.close();
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
          });
        }
      }
      this.filesDropped.emit(newFiles);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  selectOptionChanged(event: Event, index: number) {
    const selectElement = event.target as HTMLSelectElement;
    const option = selectElement.value;
    this.files[index].selectOption = option;
  }
  pickAColor(type: any) {
    switch (type) {
      case 'identification':
      case 'organization_identification':
      case 'offer':
        return '#f0ab96';
        break;
      case 'payroll':
      case 'company_deed':
      case 'offer_signed':
        return '#f0d3b1';
        break;
      case 'employment_record':
      case 'ownership':
      case 'contract_signed':
        return '#a7cce0';
        break;
      case 'bank_account_ownership':
      case 'balance':
      case 'guarantee_contract':
        return '#9daff0';
        break;
      case 'receipt':
      case 'p_and_l':
      case 'guarantee_contract_signed':
        return '#c19fc2';
        break;
      case 'pension_revaluation':
      case '200_organization_income_tax_return':
      case 'delivery_certificate':
        return '#f5f3a8';
        break;
      case '037_census_declaration':
      case '303_vat_return':
      case 'contract':
        return '#ded9d5';
        break;
      case '100_income_tax_return':
      case '390_vat_summary':
        return '#8db99e';
        break;
      case '130_quaterly_income_tax_return':
        return '#4fc3c2';
        break;
      case '131_quaterly_income_tax_return':
        return '#a2d4df';
        break;
        break;
      case 'aux1':
        return '#eaa3b5';
        break;
      case 'aux2':
        return '#c2dbbe';
        break;
      case 'axu3':
        return '#f9f6c1';
        break;
      case 'aux4':
        return '#f7bd98';
      default:
        return '#d0e5e8';
        break;
    }
  }
}
