import { Component, ViewChild } from '@angular/core';
import { AlertService } from '../../core/services/alert/alert.service';
import { OrdersService } from '../../core/services/orders/orders.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule, Location } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FilterComponent } from './filter/filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
import { UsersService } from '../../core/services/users/users.service';
import { AccountsService } from '../../core/services/accounts/accounts.service';
import { FormAddOrderComponent } from '../orders/form-add-order/form-add-order.component';
import { ClientsService } from '../../core/services/clients/clients.service';

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any;
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
}

@Component({
  selector: 'app-notes',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormAddOrderComponent,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    FilterComponent,
    MatCheckboxModule,
    MatTooltipModule,
  ],
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.css',
})
export class NotesComponent {
  displayedColumnsBroker: string[] = [
    'action_required',
    'broker_agent',
    'client',
    'vehicle',
    'note',
    'date',
    'actions',
  ];

  displayedColumnsSupplier: string[] = [
    'action_required',
    'supplier_agent',
    'client',
    'vehicle',
    'note',
    'date',
    'actions',
  ];

  displayedColumns: string[] = [];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  pageNumber: number = 1;
  pageSize: number = 0;
  pageIndex: number = 0;
  title = 'notes';
  dataSource = new MatTableDataSource<any>();
  order_id?: any;
  showForm = false;
  orderData?: any;
  type: any = 'broker';
  cargaladata: any = [];
  ladata: any = [];

  reference?: any;

  constructor(
    private alertService: AlertService,
    private router: Router,
    private orderService: OrdersService,
    private titleService: Title,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private userService: UsersService,
    private accountService: AccountsService,
    private clientsService: ClientsService,
    private location: Location
  ) {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.type = this.localStorageService.getItem('account_type');
    this.displayedColumns =
      this.type === 'broker'
        ? this.displayedColumnsBroker
        : this.displayedColumnsSupplier;
  }

  ngOnInit() {
    this.fetchAllData();
  }

  private fetchAllData() {
    Promise.all([
      this.getData(),
      this.fetchData2(),
      this.fetchData5(),
      this.fetchData6(),
      this.fetchData7(),
    ]).then(() => {
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }
  searchParams: any = {};

  getData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.alertService.loading('swal_messages.please_wait');
      this.orderService
        .getNotesLast(this.pageNumber, this.pageSize, this.searchParams)
        .subscribe({
          next: (data) => {
            this.allNotes = data.data;
            console.log('NOTAS ', this.allNotes);
            this.dataSource.data = this.allNotes;
            this.dataSource.sort = this.sort;
            this.alertService.close();
            this.pageNumber = data.meta.current_page;
            this.pageSize = data.meta.per_page;
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.meta.total;

            resolve();
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchAllData();
  }

  tab = 'notes';

  getOrder(reference: string): void {
    this.alertService.loading('swal_messages.please_wait');
    const nuevaURL = this.location.path();

    if (!nuevaURL.includes('notes')) {
      const nuevaURL = this.location.path() + '/notes/' + reference;
      this.location.go(nuevaURL);
    } else {
      const index = nuevaURL.indexOf('/');
      this.location.go(nuevaURL.substring(0, index) + '/notes/' + reference);
    }

    this.order_id = reference;
    this.orderService.searchOrder(this.order_id).subscribe({
      next: (data) => {
        this.orderData = data.data;
        let title = data.data.client?.name
          ? data.data.client?.name +
            ' ' +
            (data.data.client?.lastname ? data.data.client?.lastname : '')
          : data.data.broker.reference;
        this.titleService.setTitle(
          this.translate.instant('pages.titles.' + this.title) +
            ' ' +
            title +
            '- DBM'
        );
        this.showForm = true;
        // this.alertService.close();
      },
      error: (error) => {
        this.alertService.error('swal_messages.order_not_found');
      },
    });
  }

  toggleActionRequired(row: any): void {
    const newActionRequiredState = !row.action_required;

    this.orderService
      .updateActionRequiredBatch([row.order_id], newActionRequiredState)
      .subscribe({
        next: () => {
          row.action_required = newActionRequiredState;
          if (this.selection.has(row)) {
            this.selection.delete(row);
          }
          this.orderService.getActionRequiredCount().subscribe({
            next: (response) => {
              const count = response.data?.action_requireds || 0;
              this.orderService.updateActionRequiredCount(count);
            },
            error: (err) => {
              console.error(
                'Error al obtener el número de notas tras actualización:',
                err
              );
            },
          });
        },
        error: (error) => {
          this.alertService.error('Error al actualizar el estado de la nota');
          console.error(error);
        },
      });
  }

  allNotes: any[] = [];

    filterChanged2(searchParams: any): void {
    this.pageNumber = 1;
    const filteredData = this.allNotes.filter((note: any) => {
      if (
        searchParams.action_required !== undefined &&
        searchParams.action_required !== null
      ) {
        return note.action_required === searchParams.action_required;
      }
      return true;
    });

    this.dataSource.data = filteredData;
  }

  /******* EVENTO DEL FILTRO  *******/

  filterChanged(searchTerm: any) {
    this.alertService.loading('swal_messages.please_wait');
    this.searchParams = searchTerm;
    this.orderService
      .getNotesLast(this.pageNumber, this.pageSize, this.searchParams)
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(
              this.localStorageService.getItem('per_page')
            );
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
          this.alertService.close();
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  /******************* */

  getBadgeColorVehicle(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
    };
    return statusColorMap[status] || 'text-new';
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }

  /************ */
  selection = new Set<any>();

  updateSelectedNotes(actionRequired: boolean): void {
    const selectedIds = Array.from(this.selection).map((note) => note.order_id);

    if (selectedIds.length === 0) {
      return;
    }

    this.orderService
      .updateActionRequiredBatch(selectedIds, actionRequired)
      .subscribe({
        next: () => {
          this.allNotes.forEach((note) => {
            if (selectedIds.includes(note.order_id)) {
              note.action_required = actionRequired;
            }
          });
          this.alertService.success('swal_messages.notes.updated_succesfully');
          this.selection.clear();
          this.updateActionRequiredCount();
        },
        error: (error) => {
          this.alertService.error('Error al actualizar las notas.');
        },
      });
  }

  updateActionRequiredCount(): void {
    const actionRequiredCount = this.allNotes.filter(
      (note) => note.action_required
    ).length;
    this.orderService.updateActionRequiredCount(actionRequiredCount);
  }

  toggleSelection(row: any): void {
    if (this.selection.has(row)) {
      this.selection.delete(row);
    } else {
      this.selection.add(row);
    }
  }

  toggleSelectAll(event: Event): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.allNotes.forEach((note) => this.selection.add(note));
    }
  }

  isAllSelected(): boolean {
    return this.allNotes && this.selection.size === this.allNotes.length;
  }

  isIndeterminate(): boolean {
    return (
      this.selection.size > 0 && this.selection.size < this.allNotes.length
    );
  }

  count: number = 0;

  fetchData5(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getUserAgent().subscribe({
        next: (data) => {
          this.cargaladata.agentData = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData6(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.clientsService.getData().subscribe({
        next: (data) => {
          const updatedResponse = data.data.map((client: any) => {
            return {
              ...client,
              fullname: `${client.name}${
                client.lastname ? ` ${client.lastname}` : ''
              }`.trim(),
            };
          });
          this.cargaladata.clientData = updatedResponse;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData7(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.orderService.getStages().subscribe({
        next: (data) => {
          this.cargaladata.stages = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  vendorData: AccountData[] = [];
  brokerData: AccountData[] = [];
  supplierData: AccountData[] = [];

  fetchData2(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve();
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.vendorData = data.filter(
            (item) => item.type === 'vendor'
          );
          this.cargaladata.brokerData = data.filter(
            (item) => item.type === 'broker'
          );
          this.cargaladata.supplierData = data.filter(
            (item) => item.type === 'supplier'
          );
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  /************* */

  document_buttons_chico = false;
  showAssigneeForm = false;
  assignee = null;
  showAddClientForm = false;

  cerrarFormulario() {
    this.titleService.setTitle('Notas - DBM');
    const nuevaURL = this.location.path() + '/notes';
    const index = nuevaURL.indexOf('/');
    this.refrescar();
    this.location.go(nuevaURL.substring(0, index) + '/notes');
    this.orderData = null;
    this.showForm = false;
  }

  refrescar() {
    this.getData();
  }

  closeAssigneeForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAssigneeForm = false;
  }
  openClientForm() {
    this.document_buttons_chico = true;
    this.showAddClientForm = true;
  }
  closeClientForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAddClientForm = false;
  }
  openAssigneeForm(assignee: any) {
    this.assignee = assignee;
    this.document_buttons_chico = true;
    this.showAssigneeForm = true;
  }
}
