<form [formGroup]="form">
  <div class="form-section">
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="stage" class="form-label"
            >{{ "forms.inputs.stage" | translate }}
            <span class="required">*</span></label
          >
          <select id="stage" class="form-control" formControlName="stage" (ngModelChange)="changeStage($event)">
            <option [value]="orderData.stage" selected>
              {{ "forms.inputs.status_select." + orderData.stage | translate }}
            </option>
            <option
              *ngFor="let type of orderData.stages"
              [value]="type"
              [selected]="type === orderData.stage"
            >
              {{ "forms.inputs.status_select." + type | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label for="notes" class="form-label">{{
            "forms.inputs.addnote" | translate
          }}</label>
          <textarea
            class="form-control textarea150"
            id="notes"
            rows="5"
            formControlName="notes"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="btn-send">
    <button
      type="submit"
      class="btn btn-primary btn-primary-form"
      (click)="onSubmit()"
    >
      {{ "buttons.send" | translate }}
    </button>
  </div> -->
</form>
