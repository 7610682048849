import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../../core/services/alert/alert.service';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-recover-password',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslateModule],
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.css',
})
export class RecoverPasswordComponent {
  form: FormGroup;
  emailSent: boolean = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
  ) {
    this.form = this.fb.group({
      email: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    const email = this.form.get('email')?.value;

    this.alertService.loading('swal_messages.please_wait');

    this.authService.sendResetLink(email).subscribe({
      next: () => {
        this.alertService.successOk('swal_messages.complete_success');
        this.emailSent = true;
        this.form.reset();
      },
      error: (error) => {
        this.alertService.error('swal_messages.reset_email_error');
      },
      complete: () => {
        this.alertService.close();
      },
    });
  }
}
