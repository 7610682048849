<div class="d-flex">
  <app-tabs-form [activeForm]="activeForm" [draft]="draft" (changeForm)="changeForm($event)" [updatesTabs]="updatesTabs"
    [document_buttons_chico]="document_buttons_chico"></app-tabs-form>

  <div class="card w-100 form-right">
    <div class="back">
      <h2 *ngIf="!draft">{{ "forms.names.addinvoice" | translate }}</h2>
      <div *ngIf="draft" class="div-title">
        <h2 *ngIf="invoiceData.serial">
          {{ "forms.names.invoice" | translate }} {{ title }}
        </h2>
        <span class="badge text-badge"
          [ngClass]="getBadgeColor(stage,invoiceData.require_action,invoiceData.priority)">{{
          "badge." + stage | translate
          }}</span>
        <span *ngIf="invoiceData.client?.name" class="badge text-badge"
          [ngClass]="getBadgeColorClient(invoiceData.client?.type)">{{
          "forms.inputs." + invoiceData.client?.type | translate
          }}</span>
        <span *ngIf="invoiceData?.guarantee?.name" class="badge text-badge"
          [ngClass]="getBadgeColorClient('guarantee')">{{
          "forms.inputs.with_guarantee" | translate
          }}</span>
      </div>
      <i class="icon-cross2" (click)="emitBackButtonClick()"></i>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form">
          <form [formGroup]="form">

            <div [hidden]="activeForm !== 'general'">
              <app-general-form [invoiceData]="invoiceData" [ladata]="ladata"
                (emitSubmit)="onSubmitOrderFast($event)"></app-general-form>
            </div>
            <!-- <div>
              <app-orders-form [invoice_reference]="invoice_reference"></app-orders-form>
            </div> -->
            <div *ngIf="draft">
              <div [hidden]="activeForm !== 'orders'">
                <app-orders-form (openFormAddOrders)="OpenFormAddOrders()" (updatedOrders)="emitUpdatedOrders()"
                  [invoiceData]="invoiceData" [invoice_reference]="invoice_reference"></app-orders-form>
              </div>
              <div [hidden]="activeForm !== 'notes'">
                <app-form-add-notes [invoice_reference]="invoice_reference"
                  [activeForm]="activeForm"></app-form-add-notes>
              </div>
              <div *ngIf="activeForm == 'timeline'">
                <app-timeline [invoice_reference]="invoice_reference"></app-timeline>
              </div>
              <div *ngIf="activeForm == 'doc-order'">
                <app-form-add-document [invoice_reference]="invoiceData.reference" [invoiceData]="invoiceData"
                  [invoice]="invoiceData" [selectTypes]="ladata.selectTypes" [activeForm]="activeForm"
                  (filesArrayChanged)="actualizarEstadoArchivos($event)"></app-form-add-document>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="div-form"></div>

      <div class="btn-send d-flex align-items-center justify-content-between w-100 ml-4"
        *ngIf="activeForm != 'timeline'">
        <div class="clear">
          <button *ngIf="activeForm == 'general' && back != ''" class="btn btn-hover clear-button  ml-15" type="button"
            (click)="onSubmitOrderFast(back)">
            <i class="icon-chevron-left"></i> {{ "buttons.back_stage" | translate }} {{ "forms.inputs.status_select." +
            back | translate }}
          </button>
        </div>
        <button *ngIf="draft && !isDocumentForm()" type="submit" class="btn btn-primary btn-primary-form"
          (click)="onSubmitDraft()">
          <span>
            {{ "buttons.update_invoice" | translate }}
          </span>
        </button>
        <button *ngIf="!draft && !isDocumentForm()" type="submit" class="btn btn-primary btn-primary-form"
          (click)="onSubmit()">
          {{ "buttons.createdraft" | translate }}
        </button>

        <button *ngIf="draft && isDocumentForm()" type="submit" class="btn btn-primary btn-primary-form"
          (click)="onSubmitDraft()">
          <span *ngIf="docFiles.length == 0">
            {{ "buttons.update_invoice" | translate }}
          </span>
          <span *ngIf="docFiles.length > 0">
            {{ "buttons.upload_invoice" | translate }}
            <i class="icon-upload2"></i>
          </span>
        </button>

      </div>
    </div>
  </div>
</div>
