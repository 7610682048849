import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AutomationService {
  private apiUrl = environment.apiURL; // URL del API
  private apiUrlAuto = this.apiUrl + 'automation-rules'; // URL del API
  constructor(private http: HttpClient) {}

  // Método para obtener datos de la API
  getActions(): Observable<any> {
    return this.http.get(this.apiUrl + 'actions');
  }
  getEvent(): Observable<any> {
    return this.http.get(this.apiUrl + 'events');
  }
  getCondition(): Observable<any> {
    return this.http.get(this.apiUrl + 'conditions');
  }
  getAutomations(): Observable<any> {
    return this.http.get(this.apiUrlAuto);
  }
  searchAutomation(item: any): Observable<any> {
    return this.http.get(this.apiUrlAuto+'/'+item);
  }
  updateData(reference: string, body: any): Observable<any> {
    return this.http.put(this.apiUrlAuto + '/' + reference + '/update', body);
  }
  deleteAutomation(reference: string): Observable<any> {
    return this.http.delete(this.apiUrlAuto + '/' + reference + '/delete');
  }
  // Método para añadir datos a la API
  addData(item: any): Observable<any> {
    return this.http.post(this.apiUrlAuto + '/new', item);
  }
}
