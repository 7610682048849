import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private apiUrl = environment.apiURL + 'documents'; // URL del API

  constructor(private http: HttpClient) {}

  addDocument(item: any): Observable<any> {
    return this.http.post(this.apiUrl + '/new', item);
  }

  getOrderDocuments(item: any): Observable<any> {
    return this.http.get(this.apiUrl + '/order/' + item);
  }

  getinvoiceDocuments(item: any): Observable<any> {
    return this.http.get(this.apiUrl + '/invoice/' + item);
  }

  getClientDocuments(item: any): Observable<any> {
    return this.http.get(this.apiUrl + '/client/' + item);
  }

  downloadAllDocuments(item: any): Observable<any> {
    return this.http.get(
      environment.apiURL + 'orders/' + item + '/documents-zip'
    );
  }
}
