import { Component } from '@angular/core';
import { AuthService } from '../../core/services/auth/auth.service';
import { CommonModule } from '@angular/common'; // Asegúrate de importar CommonModule
import { TranslateModule } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService } from '../../core/services/alert/alert.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslateModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onLogin(): void {
    if (this.form.valid) {
      this.alertService.loading("swal_messages.please_wait");
      this.authService.login(this.form.value).subscribe(
        (response) => {
          Swal.close();
          if (this.localStorageService.getItem('account_type') === 'broker') {
            this.router.navigate(['/orders/drafts']);
          } else {
            this.router.navigate(['/orders/contracts']);
          }
        },
        (error) => {
          this.alertService.warning('swal_messages.login.login_failed');
          console.error(error);
        }
      );
    } else {
      this.alertService.warning('swal_messages.login.please_complete_form');
    }
  }
}
