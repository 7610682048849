import { Routes } from '@angular/router';
import { InvoicesComponent } from '../../pages/invoices/invoices.component';
import { TitleResolver } from '../resolver/metatitles/metatitles.resolver';

export const invoicesRoutes: Routes = [
  {
    path: 'invoices',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.invoices' },
  },
  {
    path: 'invoices/add',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.drafts' },
  },
  {
    path: 'invoices/drafts',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.drafts' },
  },
  {
    path: 'invoices/pending',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.pending' },
  },
  {
    path: 'invoices/paid',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.paid' },
  },
  {
    path: 'invoices/cancelled',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.cancelled' },
  },
  {
    path: 'invoices/all',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.all' },
  },
  {
    path: 'invoices/drafts/:reference',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.drafts' },
  },
  {
    path: 'invoices/pending/:reference',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.pending' },
  },
  {
    path: 'invoices/paid/:reference',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.paid' },
  },
  {
    path: 'invoices/cancelled/:reference',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.cancelled' },
  },
  {
    path: 'invoices/all/:reference',
    component: InvoicesComponent,
    resolve: { title: TitleResolver },
    data: { title: 'titles.all' },
  },
];
