import { Component, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonModule, Location } from '@angular/common';
import { OrdersService } from '../../core/services/orders/orders.service';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../core/services/alert/alert.service';
import { Automation } from '../../core/interfaces/automations';
import { FormAddAutomationComponent } from './form-add-automation/form-add-automation.component';
import { AutomationService } from '../../core/services/automation/automation.service';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-automation',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    FormAddAutomationComponent,
    RouterLink
  ],
  templateUrl: './automation.component.html',
  styleUrl: './automation.component.css',
})
export class AutomationComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  showForm = false;
  dataSource = new MatTableDataSource<Automation>();
  automation_reference?: any;
  automationData?: any;

  displayedColumns: string[] = [
    'name',
    'description',
    'events',
    'conditions',
    'actions',
    'enabled',
    'rowActions',
  ];

  constructor(
    private automationService: AutomationService,
    private alertService: AlertService,
    private location: Location
  ) {}

  ngAfterViewInit() {
    this.fetchData();
  }

  fetchData(): void {
    this.alertService.loading();
    this.automationService.getAutomations().subscribe({
      next: (data) => {
        this.dataSource.data = data.data;

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.alertService.close();
      },
      error: (error) => console.error('There was an error!', error),
    });
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  getBadgeColor(status: string) {
    const statusMap: { [key: string]: string } = {
      draft: 'text-bg-secondary',
      received: 'text-bg-info',
      pending: 'text-bg-danger',
      approved: 'text-bg-success',
      completed: 'text-bg-success',
      uncompleted: 'text-bg-warning',
      in_progress: 'text-bg-warning',
      rejected: 'text-bg-warning',
    };

    return statusMap[status] || 'text-bg-primary';
  }

  cerrarFormulario() {
    this.showForm = false;
  }

  refrescar() {
    this.showForm = false;
    this.fetchData();
  }

  getAutomation(reference: string): void {
    const nuevaURL = this.location.path();
    if (!nuevaURL.includes('automation')) {
      const nuevaURL =
        this.location.path() + '/settings/automation/' + reference;
      this.location.go(nuevaURL);
    } else {
      const index = nuevaURL.indexOf('/');
      this.location.go(
        nuevaURL.substring(0, index) + '/settings/automation/' + reference
      );
    }
    this.automation_reference = reference;
    this.alertService.loading('swal_messages.please_wait');
    this.automationService.searchAutomation(reference).subscribe({
      next: (data) => {
        this.alertService.close();
        this.automationData = data.data;
        this.showForm = true;
      },
      error: (error) => {
        this.alertService.error('swal_messages.order_not_found');
      },
    });
  }

  deleteAutomation(reference: string): void {
    this.alertService
      .confirmation2(
        'swal_messages.delete_automation',
        'swal_messages.delete_automation_message',
        'swal_messages.confirm',
        'swal_messages.back'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.automation_reference = reference;
          this.alertService.loading('swal_messages.please_wait');
          this.automationService.deleteAutomation(reference).subscribe({
            next: (data) => {
              this.fetchData();
            },
            error: (error) => {
              this.alertService.error('swal_messages.order_not_found');
            },
          });
        } else {
        }
      });
  }
}
