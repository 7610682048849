import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Client, Orders } from '../../../core/interfaces/orders';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { debounceTime, distinctUntilChanged, fromEvent, startWith, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent implements OnChanges {
  @ViewChild('requireActionCheckbox') requireActionCheckbox!: ElementRef;
  @ViewChild('searchBox') searchBox!: ElementRef;
  @Output() filterChanged = new EventEmitter<any>();
  @Input() ladata: any = [];
  submenuOpen: boolean = false;
  closeTimeout: any;
  isSearchTriggeredByEnter = false;

  dataSource = new MatTableDataSource<Orders>();
  orders: any[] = [];
  preventFocus: boolean = false;
  brandSearchTerm: string = '';
  agentSearchTerm: string = '';
  displayTextBrand: string = '';
  optionsVisibleBrand: boolean = false;
  searchParams: any = {};

  showDropdown = false;
  showClear = false;

  searchTerm = '';
  stageSearchTerm = '';
  clientSearchTerm = '';
  brokerSearchTerm = '';
  supplierSearchTerm = '';
  vendorSearchTerm = '';
  statusSearchTerm = '';
  searchText = '';

  accountType: string | null = null;

  searchTerms: Subject<string> = new Subject<string>();

  autoFetch: any;

  filterValues: {
    search: string | null;
    client: Client | null;
    vendor: string | null;
    supplier: string | null;
    agent: string | null;
    broker: string | null;
    brokerReference: string | null;
    vehicle_brand: string | null;
    stage: string | null;
    require_action: boolean | null;
    status: string | null;
  } = {
      search: '',
      client: null,
      vendor: null,
      supplier: null,
      agent: null,
      broker: null,
      brokerReference: null,
      vehicle_brand: null,
      stage: null,
      require_action: null,
      status: null,
    };

  constructor(
    private clientService: ClientsService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  ngAfterViewInit() {
    // Detectar cambios en el input con debounce y evitar duplicados
    this.searchTerms
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => {
        if (!this.isSearchTriggeredByEnter) {
          this.search();
        }
        this.isSearchTriggeredByEnter = false; // Resetear bandera después de debounce
      });

    // Detectar eventos de teclado para manejar Enter
    fromEvent<KeyboardEvent>(this.searchBox.nativeElement, 'keyup')
      .subscribe((event: KeyboardEvent) => {
        const term = (event.target as HTMLInputElement).value;
        this.searchTerms.next(term);
        if (event.key === 'Enter') {
          this.isSearchTriggeredByEnter = true; // Marcar como búsqueda por Enter
          this.search(); // Ejecutar búsqueda inmediata
        }
      });
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['filter']) {
        let filter = params['filter'];
        if (filter) {
          this.localStorageService.setItem('searchParams', filter);
        }
      }
    });

    // this.autoFetch = setInterval(() => {
    //   this.emitEvent();
    // }, 10000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ladata'] && changes['ladata'].currentValue) {
      if (this.ladata.flag) {
        this.checkFilters();
        this.fetchAllData();
      } else {
        this.fetchAllData();
      }

    }
  }

  private fetchAllData() {
    Promise.all([
      this.searchStatus(),
      this.searchBrands(''),
      this.searchSupplier(''),
      this.searchVendor(''),
      this.searchStages(''),
      this.searchBroker(''),
      this.searchAgent(''),

    ]).then(() => { });
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 4000); //  1 seconds
  }

  startCloseTimeout(): void {
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 500); //  1 seconds
  }

  cancelCloseTimeout(): void {
    this.showDropdown = true;
    clearTimeout(this.closeTimeout);
  }

  /****** Type ******/

  optionsVisibleSupplier: boolean = false;
  optionsVisibleBroker: boolean = false;
  optionsVisibleVendor: boolean = false;
  optionsVisibleClient: boolean = false;
  optionsVisibleStage: boolean = false;
  optionsVisibleAgent: boolean = false;
  optionsVisibleStatus: boolean = false;

  displayTextSupplier: string = '';
  displayTextBroker: string = '';
  displayTextVendor: string = '';
  displayTextClient: string = '';
  displayTextStage: string = '';
  displayTextAgent: string = '';
  displayTextStatus: string = '';

  selectedSupplier: string = '';
  selectedBroker: string = '';
  selectedVendor: string = '';

  preCargarFiltros(): void {

    if (this.searchParams['search']) {
      this.filterValues.search = this.searchParams['search'];
      this.searchText = this.searchParams['search'];
    }

    if (this.searchParams['stage[eq]']) {
      this.filterValues.stage = this.searchParams['stage[eq]'];
      this.displayTextStage = this.ladata.stages.find(
        (stage: any) => stage.key === this.filterValues.stage
      ).name;
      this.stageSearchTerm = this.displayTextStage;
      this.clearableStage = true;
      this.optionsVisibleStage = false;
      this.selectedFilters.add('stage');

    }

    if (this.searchParams['supplier_reference_id[eq]']) {
      this.filterValues.supplier = this.searchParams['supplier_reference_id[eq]'];
      this.displayTextSupplier = this.ladata.supplierData.find(
        (supplier: any) => supplier.reference === this.filterValues.supplier
      ).name;
      this.clearableSupplier = true;
      this.supplierSearchTerm = this.displayTextSupplier;
      this.selectedFilters.add('supplier');
    }

    if (this.searchParams['vendor_reference_id[eq]']) {
      this.filterValues.vendor = this.searchParams['vendor_reference_id[eq]'];
      this.displayTextVendor = this.ladata.vendorData.find(
        (vendor: any) => vendor.reference === this.filterValues.vendor
      ).name;
      this.clearableVendor = true;
      this.vendorSearchTerm = this.displayTextVendor;
      this.selectedFilters.add('vendor');
    }

    if (this.searchParams['broker_reference_id[eq]']) {
      this.filterValues.broker = this.searchParams['broker_reference_id[eq]'];
      this.displayTextBroker = this.ladata.brokerData.find(
        (broker: any) => broker.reference === this.filterValues.broker
      ).name;
      this.clearableBroker = true;
      this.brokerSearchTerm = this.displayTextBroker;
      this.selectedFilters.add('broker');
    }
    if (this.searchParams['vehicle_brand[like]']) {
      this.filterValues.vehicle_brand = this.searchParams['vehicle_brand[like]'];
      this.displayTextBrand = this.searchParams['vehicle_brand[like]'];
      this.clearableBrand = true;
      this.brandSearchTerm = this.displayTextBrand;
      this.selectedFilters.add('brand');
    }
    if (this.searchParams['client_name[like]']) {
      this.filterValues.client = this.searchParams['client_name[like]'];
      this.displayTextClient = this.searchParams['client_name[like]'];
      this.clearableClient = true;
      this.clientSearchTerm = this.displayTextClient;
      this.selectedFilters.add('client');
    }
    if (this.searchParams['broker_agent_id[eq]']) {
      this.filterValues.agent = this.searchParams['broker_agent_id[eq]'];
      this.displayTextAgent = this.ladata.agentData.find(
        (agent: any) => agent.reference === this.filterValues.agent
      ).name;
      this.clearableAgent = true;
      this.agentSearchTerm = this.displayTextAgent;
      this.selectedFilters.add('agent');
    }

    if (this.searchParams['vehicle_status[eq]']) {
      this.filterValues.status = this.searchParams['vehicle_status[eq]'];
      this.displayTextStatus = this.filteredStatus.find(
        (status: any) => status.key === this.filterValues.status
      ).name;
      this.clearableStatus = true;
      this.statusSearchTerm = this.displayTextStatus;
      this.optionsVisibleStatus = false;
      this.selectedFilters.add('status');
    }

    this.updateButtonLabel();
    this.search()
  }


  onFocus(filterType: string): void {
    this.hideOptions();
    switch (filterType) {
      case 'status':
        this.optionsVisibleStatus = true;
        break;
      case 'supplier':
        this.optionsVisibleSupplier = true;
        break;
      case 'broker':
        this.optionsVisibleBroker = true;
        break;
      case 'vendor':
        this.optionsVisibleVendor = true;
        break;
      case 'client':
        this.optionsVisibleClient = true;
        break;
      case 'stage':
        this.optionsVisibleStage = true;
        break;
      case 'agent':
        this.optionsVisibleAgent = true;
        break;
      case 'brand':
        this.optionsVisibleBrand = true;
        break;
    }
  }

  onClientOptionsFocus(): void {
    if (this.preventFocus) {
      this.preventFocus = false;
      return;
    }
    this.optionsVisibleClient = true;
  }

  /********  INPUT  **********/

  keyup(event: KeyboardEvent) {
    this.filterValues.search = (event.target as HTMLInputElement).value;
    this.searchTerms.next(this.filterValues.search);
  }

  /********  FILTER **********/

  checkFilters(): void {
    if (this.localStorageService.getItem('searchParams')) {
      this.searchParams = JSON.parse(this.localStorageService.getItem('searchParams') || '{}');
      if (Object.keys(this.searchParams).length !== 0) {
        this.preCargarFiltros();
      }

    }
  }

  stages: any[] = [];
  supplierAccounts: any[] = [];
  brokerAccounts: any[] = [];
  vendorAccounts: any[] = [];
  agentAccounts: any[] = [];



  selectOption(
    reference: string,
    name: string,
    filterType: string,
    inputElement: HTMLInputElement
  ): void {
    if (filterType === 'supplier') {
      this.selectedSupplier = reference;
      this.displayTextSupplier = name;
      this.optionsVisibleSupplier = false;
      this.filterValues.supplier = reference;
      this.selectedFilters.add('supplier');
      this.clearableSupplier = true;
    } else if (filterType === 'broker') {
      this.selectedBroker = reference;
      this.displayTextBroker = name;
      this.optionsVisibleBroker = false;
      this.filterValues.brokerReference = reference;
      this.clearableBroker = true;
      this.selectedFilters.add('broker');
    } else if (filterType === 'vendor') {
      this.selectedVendor = reference;
      this.displayTextVendor = name;
      this.optionsVisibleVendor = false;
      this.filterValues.vendor = reference;
      this.clearableVendor = true;
      this.selectedFilters.add('vendor');
    }
    this.updateButtonLabel();
    inputElement.blur();
    this.search();
  }

  /******** STATUS *******/



  filteredStatus: any[] = [];

  searchStatus(): void {
    this.filteredStatus = [
      { key: 'new', name: 'Nuevo' },
      { key: 'used', name: 'Usado' }
    ];
  }
  onSelectStatus(status: any, inputElement: HTMLInputElement): void {
    this.displayTextStatus = status.name;
    this.statusSearchTerm = status.name; // Limpiamos el término de búsqueda
    this.optionsVisibleStatus = false;
    this.filterValues.status = status.key;
    this.clearableStatus = true;
    this.selectedFilters.add('status');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }
  /******* STAGES *******/

  availableStages: string[] = [];
  filteredStages: any[] = [];

  searchStages(term: string): void {

    if (this.ladata && this.ladata.supplierData) {
      this.filteredStages = this.ladata.stages.filter((stages: any) =>
        stages.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }


  onSelectStage(stage: any, inputElement: HTMLInputElement): void {
    this.displayTextStage = stage.name;
    this.stageSearchTerm = stage.name; // Limpiamos el término de búsqueda
    this.optionsVisibleStage = false;
    this.filterValues.stage = stage.key;
    this.clearableStage = true;
    this.selectedFilters.add('stage');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* SUPPLIER *******/

  filteredSupplier: string[] = [];

  searchSupplier(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.supplierAccounts = this.ladata.supplierData.filter(
        (supplierData: any) =>
          supplierData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectSupplier(supplier: any, inputElement: HTMLInputElement) {
    this.displayTextSupplier = supplier.name;
    this.supplierSearchTerm = supplier.name;
    this.optionsVisibleSupplier = false;
    this.clearableSupplier = true;
    this.filterValues.supplier = supplier.reference;
    this.selectedFilters.add('supplier');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* VENDOR *******/

  searchVendor(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.vendorAccounts = this.ladata.vendorData.filter((vendorData: any) =>
        vendorData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectVendor(vendor: any, inputElement: HTMLInputElement) {
    this.displayTextVendor = vendor.name;
    this.vendorSearchTerm = vendor.name;
    this.optionsVisibleVendor = false;
    this.clearableVendor = true;
    this.filterValues.vendor = vendor.reference;
    this.selectedFilters.add('vendor');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* AGENT *******/

  searchAgent(term: string): void {
    if (this.ladata && this.ladata.agentData) {
      this.agentAccounts = this.ladata.agentData.filter((agentData: any) =>
        agentData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectAgent(agent: any, inputElement: HTMLInputElement) {
    this.displayTextAgent = agent.name + ' ' + agent.lastname;
    this.agentSearchTerm = agent.name + ' ' + agent.lastname;
    this.optionsVisibleAgent = false;
    this.clearableAgent = true;
    this.filterValues.agent = agent.reference;
    this.selectedFilters.add('agent');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* BROKER *******/

  searchBroker(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.brokerAccounts = this.ladata.brokerData.filter((brokerData: any) =>
        brokerData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectBroker(broker: any, inputElement: HTMLInputElement) {
    this.displayTextBroker = broker.name;
    this.brokerSearchTerm = broker.name;
    this.optionsVisibleBroker = false;
    this.clearableBroker = true;
    this.filterValues.broker = broker.reference;
    this.selectedFilters.add('broker');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******** BRAND FILTER **********/

  availableBrands: string[] = [];
  filteredBrands: any[] = [];

  searchBrands(term: string): void {
    if (this.ladata && this.ladata.brands) {
      this.filteredBrands = this.ladata.brands.filter((brand: any) =>
        brand.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectBrand(brand: any, brandInput: HTMLInputElement) {
    this.displayTextBrand = brand.name;
    this.brandSearchTerm = brand.name;
    this.optionsVisibleBrand = false;
    this.filterValues.vehicle_brand = brand.name;
    this.clearableBrand = true;
    this.updateButtonLabel();
    this.search();
    brandInput.blur();
  }

  /*******Filters label*******/

  hideOptions(): void {
    this.optionsVisibleSupplier = false;
    this.optionsVisibleBroker = false;
    this.optionsVisibleVendor = false;
    this.optionsVisibleClient = false;
    this.optionsVisibleBrand = false;
    this.optionsVisibleStage = false;
    this.optionsVisibleAgent = false;
    this.optionsVisibleStatus = false;
  }

  selectedFilters: Set<string> = new Set();

  updateButtonLabel() {
    const filterCount = this.selectedFilters.size;
    this.showClear = filterCount > 0;
  }

  get filterButtonLabel() {
    const filterCount = this.selectedFilters.size;
    return filterCount > 0 ? `Filtrar (${filterCount})` : 'Filtrar';
  }

  clearableBrand = false;
  clearableStage = false;
  clearableClient = false;
  clearableBroker = false;
  clearableAgent = false;
  clearableSupplier = false;
  clearableVendor = false;
  clearableStatus = false;

  clearSelectedOption(filter: string): void {
    switch (filter) {
      case 'status':
        this.displayTextStatus = '';
        this.statusSearchTerm = '';
        this.selectedFilters.delete('status');
        this.filterValues.status = null;
        break;
      case 'require_action':
        this.selectedFilters.delete('require_action');
        this.filterValues.require_action = null;
        this.requireActionCheckbox.nativeElement.checked = false;
        break;
      case 'agent':
        this.displayTextAgent = '';
        this.agentSearchTerm = '';
        this.clearableAgent = false;
        this.selectedFilters.delete('agent');
        this.filterValues.agent = null;
        break;
      case 'stage':
        this.displayTextStage = '';
        this.stageSearchTerm = '';
        this.clearableStage = false;
        this.selectedFilters.delete('stage');
        this.filterValues.stage = null;
        break;
      case 'client':
        this.displayTextClient = '';
        this.clientSearchTerm = '';
        this.clearableClient = false;
        this.selectedFilters.delete('client');
        this.filterValues.client = null;
        break;
      case 'broker':
        this.displayTextBroker = '';
        this.brokerSearchTerm = '';
        this.clearableBroker = false;
        this.selectedFilters.delete('broker');
        this.filterValues.broker = null;
        break;
      case 'supplier':
        this.displayTextSupplier = '';
        this.supplierSearchTerm = '';
        this.clearableSupplier = false;
        this.selectedFilters.delete('supplier');
        this.filterValues.supplier = null;
        break;
      case 'vendor':
        this.displayTextVendor = '';
        this.vendorSearchTerm = '';
        this.clearableVendor = false;
        this.selectedFilters.delete('vendor');
        this.filterValues.vendor = null;
        break;
      case 'brand':
        this.displayTextBrand = '';
        this.brandSearchTerm = '';
        this.clearableBrand = false;
        this.selectedFilters.delete('brand');
        this.filterValues.vehicle_brand = null;
        break;
    }
    this.updateButtonLabel();
    this.search();

    if (this.selectedFilters.size === 0) {
      this.showClear = false;
    }
  }

  onCheckboxChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.filterValues.require_action = true;
      this.selectedFilters.add('require_action');
      this.search();
    } else {
      this.clearSelectedOption('require_action');
    }
  }

  search(): void {
    this.searchParams = {};
    if (this.filterValues.search) {
      this.searchParams['search'] = `${this.filterValues.search}`;
    }

    if (this.filterValues.require_action) {
      this.searchParams['require_action'] = this.filterValues.require_action;
    }

    if (this.filterValues.client) {
      this.searchParams[
        'client_name[like]'
      ] = `%${this.filterValues.client.name}%`;
    }

    if (this.filterValues.status) {
      this.searchParams[
        'vehicle_status[eq]'
      ] = `${this.filterValues.status}`;
    }

    if (this.filterValues.vendor) {
      this.searchParams[
        'vendor_reference_id[eq]'
      ] = `${this.filterValues.vendor}`;
    }

    if (this.filterValues.supplier) {
      this.searchParams[
        'supplier_reference_id[eq]'
      ] = `${this.filterValues.supplier}`;
    }

    if (this.filterValues.brokerReference) {
      this.searchParams[
        'broker_reference_id[eq]'
      ] = `${this.filterValues.brokerReference}`;
    }

    if (this.filterValues.broker) {
      this.searchParams[
        'broker_reference_id[eq]'
      ] = `${this.filterValues.broker}`;
    }

    if (this.filterValues.vehicle_brand) {
      this.searchParams[
        'vehicle_brand[like]'
      ] = `%${this.filterValues.vehicle_brand}%`;
    }
    if (this.filterValues.agent) {
      if (this.accountType == 'broker') {
        this.searchParams['broker_agent_id[eq]'] = `${this.filterValues.agent}`;
      }
      if (this.accountType == 'supplier') {
        this.searchParams[
          'supplier_agent_id[eq]'
        ] = `${this.filterValues.agent}`;
      }
    }

    if (this.filterValues.stage) {
      this.searchParams['stage[eq]'] = `${this.filterValues.stage}`;
    }


    this.emitEvent();

    let store_searchParams = this.searchParams;
    //store_searchParams['search'] = '';
    this.localStorageService.setItem('searchParams', JSON.stringify(store_searchParams).replace("%", "").replace("%", ""));
    this.showClear = true;
    /* this.fetchData(); */
  }

  clearFilter(event: Event): void {
    event.stopPropagation();
    this.filterValues = {
      search: '',
      client: null,
      vendor: null,
      broker: null,
      agent: null,
      supplier: null,
      vehicle_brand: null,
      brokerReference: null,
      stage: null,
      require_action: null,
      status: null,
    };

    this.searchText = '';
    this.filteredClients = [];

    this.stageSearchTerm = '';
    this.clientSearchTerm = '';
    this.supplierSearchTerm = '';
    this.brokerSearchTerm = '';
    this.vendorSearchTerm = '';
    this.brandSearchTerm = '';
    this.stageSearchTerm = '';
    this.statusSearchTerm = '';

    this.displayTextStage = '';
    this.displayTextClient = '';
    this.displayTextBroker = '';
    this.displayTextSupplier = '';
    this.displayTextVendor = '';
    this.displayTextBrand = '';
    this.displayTextAgent = '';
    this.displayTextStatus = '';


    this.clearableStage = false;
    this.clearableClient = false;
    this.clearableBroker = false;
    this.clearableAgent = false;
    this.clearableSupplier = false;
    this.clearableVendor = false;
    this.clearableBrand = false;
    this.clearableStatus = false;

    this.localStorageService.removeItem('searchParams');

    this.requireActionCheckbox.nativeElement.checked = false;

    this.showClear = false;
    this.selectedFilters.clear();
    this.searchParams = {};
    this.updateButtonLabel();
    this.hideOptions();
    this.emitEvent();
    /* this.fetchData(); */
    //this.filterChanged.emit(this.searchParams);
  }

  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }


  filteredClients: any[] = [];
  getClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        this.clients = response.data;
      },
      error: (error) => {
        console.error('Hubo un error al obtener los clientes:', error);
      },
    });
  }

  clients: Client[] = [];

  searchClients(query: string): void {
    this.clientSearchTerm = query;
    this.clientService.getData().subscribe(
      (response) => {
        if (response && response.data) {
          const lowerCaseQuery = query.toLowerCase();
          this.filteredClients = response.data.filter(
            (client: Client) =>
              client.name?.toLowerCase().includes(lowerCaseQuery) ||
              client.lastname?.toLowerCase().includes(lowerCaseQuery) ||
              client.tax_id?.toLowerCase().includes(lowerCaseQuery)
          );
        } else {
          this.filteredClients = [];
        }
      },
      (error) => {
        console.error('Error fetching clients:', error);
        this.filteredClients = [];
      }
    );
  }
}
