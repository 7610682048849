import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  override itemsPerPageLabel : string = 'Elementos por página';
  override nextPageLabel     : string = 'Siguiente página';
  override previousPageLabel : string = 'Página anterior';
  override firstPageLabel    : string = 'Primera página';
  override lastPageLabel     : string = 'Última página';
  ofLabel : string = 'de';

  constructor(private translate: TranslateService) {
    super();
    this.translateLabels();

    // Subscribe to language changes to update labels
    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });
  }

  private translateLabels() {
    this.translate.get(['PAGINATOR.ITEMS_PER_PAGE', 'PAGINATOR.NEXT_PAGE', 'PAGINATOR.PREVIOUS_PAGE', 'PAGINATOR.FIRST_PAGE', 'PAGINATOR.LAST_PAGE','PAGINATOR.OF']).subscribe(translations => {
      this.itemsPerPageLabel = translations['PAGINATOR.ITEMS_PER_PAGE'];
      this.nextPageLabel = translations['PAGINATOR.NEXT_PAGE'];
      this.previousPageLabel = translations['PAGINATOR.PREVIOUS_PAGE'];
      this.firstPageLabel = translations['PAGINATOR.FIRST_PAGE'];
      this.lastPageLabel = translations['PAGINATOR.LAST_PAGE'];
      this.ofLabel = translations['PAGINATOR.OF'];
      this.changes.next();  // Notify the changes
    });
  }
  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };
}
