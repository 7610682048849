<div class="not-found-container">
  <div class="img404">
    <img
      src="assets/images/logo-404-dark.png"
      class="img-fluid"
      alt=""
      height="22"
    />
  </div>
  <div class="text-container">
    <h1>404</h1>
    <p>{{ "pages.not_found" | translate }}</p>
    <p class="mailto">{{ "pages.contact.text" | translate }} <a href="mailto:${support@digitalbuyersmanager.com}">{{ "pages.contact.mailto" | translate }}</a></p>
    <br>
    <div class="go-home">
      <a href="/"
        >{{ "pages.go_home" | translate }}
        <span><i class="icon-chevron-right"></i></span>
      </a>
    </div>
  </div>
</div>
