<div class="mb-3 buscador">
  <div class="input-group form-control">
    <i class="icon-magnifier"></i>
    <input [(ngModel)]="searchParams.search" type="text" placeholder="{{'buttons.clients-filter' | translate}}"
      aria-label="Buscar" matInput (keyup)="keyup($event)" class="placeholder-filter text" />
  </div>

  <div class="">
    <button class="btn btn-hover filter" type="button" (click)="toggleDropdown()">
      {{ filterButtonLabel }} <i class="icon-equalizer"></i>
    </button>
    <div *ngIf="showDropdown" class="dropdown-content" role="menu" (mouseenter)="cancelCloseTimeout()"
      (mouseleave)="startCloseTimeout()">
      <div class="filter-content">
        <div class="clear-filter mb-3">
          <div>{{ "buttons.filter" | translate }}</div>
        </div>
        <div class="mb-3">
          <div class="main-selector-container">
            <div class="selector-container-supplier" [ngClass]="{'expanded': optionsVisible}">
              <input #typeInput type="text" (focus)="onFocus()" (blur)="hideOptions()"
                placeholder="{{ 'forms.inputs.type' | translate }}" [value]="displayText" readonly class="text" />
              <span *ngIf="clearable" class="clear-icon" (click)="clearSelectedOption(); $event.stopPropagation();"><i
                  class="icon-cross2"></i></span>
              <span class="icon-chevron-down"></span>
            </div>
            <div class="options-container" *ngIf="optionsVisible" [ngClass]="{'visible': optionsVisible}">
              <div class="option-value" (mousedown)="selectOption('organization', 'Empresa', typeInput)">
                <div>{{ 'forms.inputs.company' | translate }}</div>
              </div>
              <div class="option-value" [ngClass]="{'selected': selectedValue === 'individual'}"
                (mousedown)="selectOption('individual', 'Particular', typeInput)">
                <div>{{ 'forms.inputs.individual' | translate }}</div>
              </div>
              <div class="option-value" [ngClass]="{'selected': selectedValue === 'self-employed'}"
                (mousedown)="selectOption('self-employed', 'Autónomo', typeInput)">
                <div>{{ 'forms.inputs.employee' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showClear && hasActiveFilters()" class="clear">
    <button class="btn btn-hover clear-button" type="button" (click)="clearFilter($event)">
      {{ "buttons.clearFilter" | translate }}
    </button>
  </div>

</div>
