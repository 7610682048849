import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Client } from '../../interfaces/orders';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private apiUrl = environment.apiURL + 'clients'; // URL del API

  constructor(private http: HttpClient) {}

  getData(pageNumber: number = 0, pageSize: number = 0): Observable<any> {
    return this.http.get(
      `${this.apiUrl}?page=${pageNumber}&pageSize=${pageSize}`
    );
  }

  // Método para añadir datos a la API
  addData(item: any): Observable<any> {
    return this.http.post(this.apiUrl + '/new', item);
  }

  getDataAccounts(): Observable<any> {
    return this.http.get(environment.apiURL + 'accounts');
  }

  deleteClient(reference: string): Observable<any> {
    return this.http.delete(this.apiUrl + `/${reference}` + '/delete');
  }

  updateClient(reference: string, clientData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/${reference}` + '/update', clientData);
  }

  getClientByReference(reference: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${reference}`);
  }

  searchClients(search: string): Observable<any> {
    return this.http.get<any[]>(`${this.apiUrl}?search=${search}`);
  }

  getClientsByType(
    type: string,
    pageNumber: number = 0,
    pageSize: number = 0
  ): Observable<any> {
    return this.http.get<any[]>(
      `${this.apiUrl}?type[eq]=${type}&page=${pageNumber}&pageSize=${pageSize}`
    );
  }

  getClients(params: {
    search?: string;
    type?: string;
    page: number;
    pageSize: number;
  }): Observable<any> {
    let httpParams = new HttpParams()
      .set('page', params.page.toString())
      .set('pageSize', params.pageSize.toString());

    if (params.search) {
      httpParams = httpParams.set('search', params.search);
    }

    if (params.type) {
      httpParams = httpParams.set('type[eq]', params.type);
    }

    return this.http.get<any>(this.apiUrl, { params: httpParams });
  }

  checkTaxId(taxId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/search/tax-id/${taxId}`);
  }

  uploadDocument(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/search/documents`, formData);
  }

/*   getClientsOrders(
    pageNumber: number = 0,
    pageSize: number = 0
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}?page=${pageNumber}&pageSize=${pageSize}`
    );
  } */

  getClientsOrders(reference: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${reference}/orders`);
  }
}
