import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FinancialDataService } from '../../../core/services/financialData/financial-data.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { AlertService } from '../../../core/services/alert/alert.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-financial-data',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './financial-data.component.html',
  styleUrl: './financial-data.component.css',
})
export class FinancialDataComponent {
  @Input() client_reference?: any;
  @Input() client?: any;
  @Input() activeFormTab?: any;
  @Input() clientData?: any;

  @Input() clientReference: string | undefined;

  userPackDocuments: any[] = [];

  financialStatus: any = null;

  financialData: any;

  widgetUrl: string = '';

  constructor(
    private financialDataService: FinancialDataService,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.financialDataForm = this.fb.group({
      additional_address_complement: [''],
      block: [''],
      cadastral_reference: [''],
      cadastral_reference_indicator: [''],
      door: [''],
      floor: [''],
      municipality: [''],
      number: [''],
      number_type: [''],
      portal: [''],
      postal_code: [''],
      province: [''],
      road_name: [''],
      road_type: [''],
      stair: [''],
      street_number: [''],
      town_city: [''],
    });

    this.financialDataForm2 = this.fb.group({
      codigo: [''],
      cuenta: [''],
      ingresos_cuenta: [''],
      nif_pagador: [''],
      nombre_pagador: [''],
      rendimientos_dinerarios: [''],
      rendimientos_especie: [''],
      retenciones: [],
      tipo: [''],
      titulares: [''],
    });

    this.social_security = this.fb.group({
      postal_code: [''],
      quotes: [''],
    });

    this.earned_income = this.fb.group({
      clave: [''], // 'Empleado' sería el valor por defecto si quieres asignarlo
      codigo: [''],
      gastos_deducibles: [0], // Número inicializado en 0
      ingresos_cuenta: [0],
      ingresos_cuenta_repercutidos: [0],
      nif_pagador: [''],
      nombre_pagador: [''],
      reduccion: [null], // Null como valor inicial
      retenciones: [0],
      retenciones_retribuciones_incapacidad_laboral: [null],
      retribuciones_dinerarias: [0],
      retribuciones_dinerarias_incapacidad_laboral: [null],
      retribuciones_especie: [0],
    });

    this.loans_credits_real_estate = this.fb.group({
      capital_amortizado: [''],
      codigo: [''],
      fecha_constitucion_prestamo: [''],
      gastos_financieros: [''],
      identificacion_prestamo_credito: [''],
      intereses: [''],
      nif_declarante: [''],
      nombre_declarante: [''],
      porcentaje_participacion: [''],
    });
  }

  ngOnInit(): void {
    this.loadClientRequests();
  }

  noDocumentsFound = true;

  loadUserPackDocuments(): void {
    const userReference = this.localStorageService.getItem('user_reference');

    if (userReference) {
      this.financialDataService.getUserPackDocuments(userReference).subscribe({
        next: (data) => {
          if (data && data.data.length > 0) {
            this.userPackDocuments = data.data;
            this.noDocumentsFound = false;
          } else {
            this.noDocumentsFound = true;
          }
          console.log(
            'Documentos del paquete financiero:',
            this.userPackDocuments
          );
        },
        error: (err) => {
          console.error(
            'Error al obtener los documentos del paquete financiero:',
            err
          );
          this.noDocumentsFound = true;
        },
      });
    }
  }

  loadFinancialStatus(): void {
    this.financialDataService.getFinancialStatus().subscribe({
      next: (data) => {
        this.financialStatus = data;
        console.log('Estado financiero:', this.financialStatus);
      },
      error: (err) => {
        console.error('Error al obtener el estado financiero:', err);
        this.alertService.error('Error al obtener el estado financiero');
      },
    });
  }

  getFinancialData(requestId: string): void {
    this.financialDataService.getFinancialDataRequest(requestId).subscribe({
      next: (data) => {
        this.financialData = data;

        console.log(
          ' financialData.data.aeat.road_name',
          this.financialData.data.aeat.road_name
        );
        console.log('Datos financieros:', this.financialData);
        this.populateForm();
      },
      error: (error) => {
        console.error('Error obteniendo los datos financieros', error);
      },
    });
  }

  getFinancialData2(): void {
    const clientReference = this.client_reference;

    this.financialDataService
      .getFinancialDataRequest2(clientReference)
      .subscribe({
        next: (data) => {
          this.financialData = data;
          console.log('Datos financieros nuevo endpoint:', this.financialData);
        },
        error: (error) => {
          console.error('Error obteniendo los datos financieros', error);
        },
      });
  }

  /*   requestClavePin() {
    this.alertService.loading('swal_messages.belender.generating_request');
    const clientReference = this.client_reference;

    this.financialDataService.requestClavePin(clientReference).subscribe({
      next: (response) => {
        this.alertService.close();
        console.log('Respuesta completa:', response);

        const url = response.data;
        if (url) {
          this.alertService.successOk(
            'swal_messages.belender.succesfully_request'
          );

          const requestId = this.extractRequestIdFromUrl(url);
          console.log('Request ID obtenido:', requestId);
          this.widgetUrl = url;
          this.getFinancialData(requestId);
        } else {
          this.alertService.error('swal_messages.belender.invalid_response');
        }
      },
      error: (error) => {
        console.error('Error en la solicitud:', error);
        this.alertService.error('swal_messages.belender.request_error');
      },
    });
  } */

  requestClavePin() {
    this.alertService.loading('swal_messages.belender.generating_request');
    const clientReference = this.client_reference;

    this.financialDataService.getClientRequests(clientReference).subscribe({
      next: (response) => {
        console.log('response', response);
        if (response.data && response.data.length > 0) {
          const lastRequest = response.data[response.data.length - 1];
          this.alertService.successOk('swal_messages.belender.request_found');
          this.widgetUrl = lastRequest.widget_url;

          console.log('widget URL', this.widgetUrl);
          this.getFinancialData(lastRequest.request_id);
        } else {
          this.createNewRequest(clientReference);
        }
      },
      error: (error) => {
        console.error('Error al obtener solicitudes previas:', error);
        this.alertService.error('swal_messages.belender.request_error');
      },
    });
  }

  createNewRequest(clientReference: string) {
    this.financialDataService.requestClavePin(clientReference).subscribe({
      next: (response) => {
        this.alertService.close();
        console.log('Respuesta completa:', response);

        const url = response.data;
        if (url) {
          this.alertService.successOk(
            'swal_messages.belender.succesfully_request'
          );
          const requestId = this.extractRequestIdFromUrl(url);
          console.log('Request ID obtenido:', requestId);
          this.widgetUrl = url;
          this.getFinancialData(requestId);
        } else {
          this.alertService.error('swal_messages.belender.invalid_response');
        }
      },
      error: (error) => {
        console.error('Error en la solicitud:', error);
        this.alertService.error('swal_messages.belender.request_error');
      },
    });
  }

  extractRequestIdFromUrl(url: string): string {
    const regex = /request_id=([a-zA-Z0-9-]+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
  }

  openedPackageIndex: number | null = null;

  togglePackage(index: number): void {
    this.openedPackageIndex = this.openedPackageIndex === index ? null : index;
  }

  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  copyToClipboard() {
    const tempInput = document.createElement('input');
    tempInput.value = this.widgetUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.alertService.successOk('URL copiada al portapapeles');
  }

  isLoading: boolean = true; // Indica si los datos están cargando

  /*   loadClientRequests(): void {
    const clientReference = this.client_reference;

    this.financialDataService.getClientRequests(clientReference).subscribe({
      next: (response) => {
        if (response.data && response.data.length > 0) {
          // Si hay solicitudes previas, cargar la última URL generada
          const lastRequest = response.data[response.data.length - 1];
          this.widgetUrl = lastRequest.widget_url;
          console.log('Solicitud previa encontrada:', this.widgetUrl);
          this.getFinancialData(lastRequest.request_id); // Cargar datos financieros con el request_id
        } else {
          // Si no hay solicitudes previas, mantener oculto el campo URL
          console.log('No se encontraron solicitudes previas.');
          this.widgetUrl = ''; // No mostrar ninguna URL si no hay solicitudes
        }
      },
      error: (error) => {
        console.error(
          'Error al cargar las solicitudes previas del cliente:',
          error
        );
      },
    });
  } */
  loadClientRequests(): void {
    this.isLoading = true; // Inicia el estado de carga

    const clientReference = this.client_reference;

    this.financialDataService.getClientRequests(clientReference).subscribe({
      next: (response) => {
        if (response.data && response.data.length > 0) {
          // Si hay solicitudes previas, cargar la última URL generada
          const lastRequest = response.data[response.data.length - 1];
          this.widgetUrl = lastRequest.widget_url;
          console.log('Solicitud previa encontrada:', this.widgetUrl);
          this.getFinancialData(lastRequest.request_id); // Cargar datos financieros con el request_id
        } else {
          console.log('No se encontraron solicitudes previas.');
          this.widgetUrl = ''; // No mostrar ninguna URL si no hay solicitudes
        }
      },
      error: (error) => {
        console.error(
          'Error al cargar las solicitudes previas del cliente:',
          error
        );
      },
      complete: () => {
        this.isLoading = false; // Finaliza el estado de carga
      },
    });
  }

  @Input() document_buttons_chico = false;

  @Output() emitOpenFinancialDataForm = new EventEmitter<boolean>();

  openDataForm(section: string) {
    this.document_buttons_chico = true;
    this.selectedSection = section;
    this.emitOpenFinancialDataForm.emit(this.financialData);
    console.log('financial data', this.financialData);
  }

  selectedSection: string | null = null;

  openSection() {}

  closeSection() {
    this.selectedSection = null;
  }

  sections: { [key: string]: boolean } = {
    address: false,
    contributions_special_social_security: false,
    earned_bank_accounts: false,
    earned_income: false,
    loans_credits_real_estate: false,
    social_security: false,
  };

  toggleSection(section: string): void {
    this.sections[section] = !this.sections[section];
  }

  financialDataForm: FormGroup;
  financialDataForm2: FormGroup;
  social_security: FormGroup;
  earned_income: FormGroup;
  loans_credits_real_estate: FormGroup;

  populateForm(): void {
    const address = this.financialData.data.aeat.address;

    const earned_bank_accounts =
      this.financialData.data.aeat.earned_bank_accounts.data[0];

    const seguridad_social = this.financialData.data.seguridad_social.data;

    const earned_income = this.financialData.data.aeat.earned_income.data[0];

    const loans_credits_real_estate =
      this.financialData.data.aeat.loans_credits_real_estate[0];

    console.log('loans_credits_real_estate', loans_credits_real_estate.codigo);

    if (address) {
      this.financialDataForm.patchValue({
        road_type: address.road_type,
        road_name: address.road_name,
        number: address.number,
        postal_code: address.postal_code,
        municipality: address.municipality,
        province: address.province,
      });
    }
    if (earned_bank_accounts) {
      this.financialDataForm2.patchValue({
        codigo: earned_bank_accounts.codigo,
        cuenta: earned_bank_accounts.cuenta,
        ingresos_cuenta: earned_bank_accounts.ingresos_cuenta,
        nif_pagador: earned_bank_accounts.nif_pagador,
        nombre_pagador: earned_bank_accounts.nombre_pagador,
        rendimientos_dinerarios: earned_bank_accounts.rendimientos_dinerarios,
        rendimientos_especie: earned_bank_accounts.rendimientos_especie,
        retenciones: earned_bank_accounts.retenciones,
        tipo: earned_bank_accounts.tipo,
        titulares: earned_bank_accounts.titulares,
      });
    }
    if (seguridad_social) {
      this.social_security.patchValue({
        postal_code: seguridad_social.postal_code,
        quotes: seguridad_social.quotes,
      });
    }

    if (earned_income) {
      this.earned_income.patchValue({
        clave: earned_income.clave,
        codigo: earned_income.codigo,
        gastos_deducibles: earned_income.gastos_deducibles,
        ingresos_cuenta: earned_income.ingresos_cuenta,
        ingresos_cuenta_repercutidos:
          earned_income.ingresos_cuenta_repercutidos,
        nif_pagador: earned_income.nif_pagador,
        nombre_pagador: earned_income.nombre_pagador,
        reduccion: earned_income.reduccion,
        retenciones: earned_income.retenciones,
        retenciones_retribuciones_incapacidad_laboral:
          earned_income.retenciones_retribuciones_incapacidad_laboral,
        retribuciones_dinerarias: earned_income.retribuciones_dinerarias,
        retribuciones_dinerarias_incapacidad_laboral:
          earned_income.retribuciones_dinerarias_incapacidad_laboral,
        retribuciones_especie: earned_income.retribuciones_especie,
      });
    }

    if (loans_credits_real_estate) {
      this.loans_credits_real_estate.patchValue({
        capital_amortizado: loans_credits_real_estate.capital_amortizado,
        codigo: loans_credits_real_estate.codigo,
        fecha_constitucion_prestamo:
          loans_credits_real_estate.fecha_constitucion_prestamo,
        gastos_financieros: loans_credits_real_estate.gastos_financieros,
        identificacion_prestamo_credito:
          loans_credits_real_estate.identificacion_prestamo_credito,
        intereses: loans_credits_real_estate.intereses,
        nif_declarante: loans_credits_real_estate.nif_declarante,
        nombre_declarante: loans_credits_real_estate.nombre_declarante,
        porcentaje_participacion:
          loans_credits_real_estate.porcentaje_participacion,
      });
    }
  }
}
