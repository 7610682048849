import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private apiUrl = environment.apiURL+'brands'; // URL del API

  constructor(private http: HttpClient) {}

  // Método para obtener datos de la API
  getData(): Observable<any> {
    return this.http.get(this.apiUrl);
  }

  // Método para añadir datos a la API
  addData(item: any): Observable<any> {
    return this.http.post(this.apiUrl+"/new", item);
  }

}
