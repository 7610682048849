import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

// Primer pipe
@Pipe({
  name: 'updatePipe',
  standalone: true,
})
export class updatePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}
  transform(value: string): any {
    let formattedString = value;
    const splitData = value.split('*@*');
    if (splitData.length > 3) {
      if (splitData[0] == "updated_by"){
        return null;
      }
      formattedString =
        this.translate.instant('forms.inputs.' + splitData[0]) +
        this.translate.instant('pipe.update') +
        (splitData[1] === ''
          ? this.translate.instant('pipe.null')
          : splitData[1]) +
        this.translate.instant('pipe.to') +
        splitData.slice(2).join('-');
    }
    if (splitData.length == 3) {
      if (splitData[0] == "updated_by"){
        return null;
      }
      formattedString =
        this.translate.instant('forms.inputs.' + splitData[0]) +
        this.translate.instant('pipe.update') +
        (splitData[1] === ''
          ? this.translate.instant('pipe.null')
          : splitData[1]) +
        this.translate.instant('pipe.to') +
        splitData[2];
    }
    /*  if (splitData.length > 3) {
      return this.translate.instant('pipe.data_label') + ": " + this.translate.instant( "forms.inputs."+splitData[0])  +
      ", " + this.translate.instant('pipe.old_data_label') + ": " + splitData[1] +
      ", " + this.translate.instant('pipe.new_data_label') + ": " + splitData.slice(2).join('-');
  }
  if (splitData.length == 3) {
      return this.translate.instant('pipe.data_label') + ": " +this.translate.instant( "forms.inputs."+splitData[0])  +
      ", " + this.translate.instant('pipe.old_data_label') + ": " + splitData[1] +
      ", " + this.translate.instant('pipe.new_data_label') + ": " + splitData[2] ;
  }  */

    return this.sanitizer.bypassSecurityTrustHtml(formattedString);
  }
}

// Primer pipe
@Pipe({
  name: 'updatePipe2',
  standalone: true,
})
export class updatePipe2 implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}
  transform(value: string): any {
    let formattedString = value;
    const splitData = value.split('*@*');
    if (splitData.length > 2) {
      formattedString =
        this.translate.instant('pipe.upload') +
        this.translate.instant('select.document_type.' + splitData[0]) +
        this.translate.instant('pipe.name') +
        (splitData[1] === ''
          ? this.translate.instant('pipe.null')
          : splitData.slice(1).join('-'));
    }
    if (splitData.length == 2) {
      formattedString =
        this.translate.instant('pipe.upload') +
        this.translate.instant('select.document_type.' + splitData[0]) +
        this.translate.instant('pipe.name') +
        (splitData[1] === ''
          ? this.translate.instant('pipe.null')
          : splitData[1]);
    }
    return this.sanitizer.bypassSecurityTrustHtml(formattedString);
  }
}

// Primer pipe
@Pipe({
  name: 'updatePipe3',
  standalone: true,
})
export class updatePipe3 implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}
  transform(value: string): any {
    let formattedString =this.translate.instant('pipe.stage') + this.translate.instant('forms.inputs.status_select.' + value);
    return this.sanitizer.bypassSecurityTrustHtml(formattedString);
  }
}
@Pipe({
  name: 'updatePipe4',
  standalone: true,
})
export class updatePipe4 implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}
  transform(value: string): any {
    let formattedString =this.translate.instant('pipe.notes') + value;
    return this.sanitizer.bypassSecurityTrustHtml(formattedString);
  }
}

@Pipe({
  name: 'updatePipe5',
  standalone: true,
})
export class updatePipe5 implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}
  transform(value: string): any {
    let formattedString =  value;
    return this.sanitizer.bypassSecurityTrustHtml(formattedString);
  }
}

// Segundo pipe
@Pipe({
  name: 'capitalize',
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}

@Pipe({
  name: 'filterSelectTypes',
  standalone: true,
})
export class FilterSelectTypesPipe implements PipeTransform {
  transform(selectTypes: any[], clientType: string): any[] {
    if (!selectTypes || !clientType) {
      return selectTypes;
    }
    return selectTypes.filter((t) => t.type === clientType);
  }
}
