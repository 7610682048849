import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent {
  @Output() filteredClients = new EventEmitter<any[]>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() clearSearch = new EventEmitter<void>();

  searchParams: any = {};
  searchTerms = new Subject<string>();

  selectedFilters: Set<string> = new Set();
  showDropdown = false;
  showClear = false;
  clearable = false;
  optionsVisible = false;
  justSelected: boolean = false;

  selectedOption: string = '';
  selectedValue: string = '';
  displayText: string = '';

  optionsVisibleAgent: boolean = false;
  displayTextAgent: string = '';
  agentSearchTerm: string = '';
  clearableAgent = false;

  agentAccounts: any[] = [];
  accountType: string | null = null;

  filterValues: {
    search: string;
    action_required: boolean | null;
    vendor: string | null;
    supplier: string | null;
    agent: string | null;
    broker: string | null;
    brokerReference: string | null;
    stage: string | null;
  } = {
    search: '',
    action_required: null,
    vendor: null,
    supplier: null,
    agent: null,
    broker: null,
    brokerReference: null,
    stage: null,
  };

  @Input() ladata: any = [];

  stages: any[] = [];
  supplierAccounts: any[] = [];
  brokerAccounts: any[] = [];
  vendorAccounts: any[] = [];

  displayTextSupplier: string = '';
  displayTextBroker: string = '';
  displayTextVendor: string = '';

  optionsVisibleSupplier: boolean = false;
  optionsVisibleBroker: boolean = false;
  optionsVisibleVendor: boolean = false;

  brokerSearchTerm = '';
  supplierSearchTerm = '';
  vendorSearchTerm = '';

  clearableStage = false;
  clearableBroker = false;
  clearableSupplier = false;
  clearableVendor = false;

  selectedSupplier: string = '';
  selectedBroker: string = '';
  selectedVendor: string = '';

  constructor(private localStorageService: LocalStorageService) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  isLoading: boolean = false;

  ngOnInit() {
    this.checkFilters();
  }

  ngAfterViewInit() {
    this.searchTerms
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.search();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ladata'] && changes['ladata'].currentValue) {
      if (this.ladata.flag) {
        this.checkFilters();
        this.fetchAllData();
      } else {
        this.fetchAllData();
      }
    }
  }

  private fetchAllData() {
    Promise.all([
      this.searchAgent(''),
      this.searchStages(''),
      this.searchBroker(''),
      this.searchSupplier(''),
      this.searchVendor(''),
    ]).then(() => {});
  }

  checkFilters(): void {
    if (this.localStorageService.getItem('searchParamsNotes')) {
      this.searchParams = JSON.parse(
        this.localStorageService.getItem('searchParamsNotes') || '{}'
      );
      if (Object.keys(this.searchParams).length !== 0) {
        this.preCargarFiltros();
      }
    }
  }
  clearSelectedOption(filter: string): void {
    switch (filter) {
      case 'readed':
        this.displayText = '';
        this.selectedValue = '';
        this.selectedFilters.delete('require_action');
        this.filterValues.action_required = null;
        this.clearable = false;
        /* this.removeQueryParam('action_required'); */
        break;
      case 'agent':
        this.displayTextAgent = '';
        this.agentSearchTerm = '';
        this.clearableAgent = false;
        this.selectedFilters.delete('agent');
        this.filterValues.agent = null;
        break;
      case 'stage':
        this.displayTextStage = '';
        this.stageSearchTerm = '';
        this.clearableStage = false;
        this.selectedFilters.delete('stage');
        this.filterValues.stage = null;
        break;
      case 'broker':
        this.displayTextBroker = '';
        this.brokerSearchTerm = '';
        this.clearableBroker = false;
        this.selectedFilters.delete('broker');
        this.filterValues.broker = null;
        break;
      case 'supplier':
        this.displayTextSupplier = '';
        this.supplierSearchTerm = '';
        this.clearableSupplier = false;
        this.selectedFilters.delete('supplier');
        this.filterValues.supplier = null;
        break;
      case 'vendor':
        this.displayTextVendor = '';
        this.vendorSearchTerm = '';
        this.clearableVendor = false;
        this.selectedFilters.delete('vendor');
        this.filterValues.vendor = null;
        break;
    }
    this.updateButtonLabel();
    this.search();

    if (this.selectedFilters.size === 0) {
      this.showClear = false;
    }
  }

  updateButtonLabel() {
    const filterCount = Array.from(this.selectedFilters).filter(
      (filter) => filter !== 'search'
    ).length;

    this.showClear = filterCount > 0;
  }

  get filterButtonLabel() {
    const filterCount = Array.from(this.selectedFilters).filter(
      (filter) => filter !== 'search'
    ).length;

    return filterCount > 0 ? `Filtrar (${filterCount})` : 'Filtrar';
  }

  onFocus(filterType: string): void {
    this.hideOptions();
    switch (filterType) {
      case 'readed':
        this.optionsVisible = true;
        break;
      case 'agent':
        this.optionsVisibleAgent = true;
        break;
      case 'stage':
        this.optionsVisibleStage = true;
        break;
      case 'supplier':
        this.optionsVisibleSupplier = true;
        break;
      case 'broker':
        this.optionsVisibleBroker = true;
        break;
      case 'vendor':
        this.optionsVisibleVendor = true;
        break;
    }
  }

  hideOptions(): void {
    this.optionsVisible = false;
    this.optionsVisibleAgent = false;
    this.optionsVisibleStage = false;
    this.optionsVisibleSupplier = false;
    this.optionsVisibleBroker = false;
    this.optionsVisibleVendor = false;
  }

  clearFilter(event: Event): void {
    event.stopPropagation();

    this.filterValues = {
      search: '',
      action_required: null,
      vendor: null,
      supplier: null,
      agent: null,
      broker: null,
      brokerReference: null,
      stage: null,
    };

    this.searchTerms.next('');
    this.searchParams = {};

    this.localStorageService.removeItem('searchParamsNotes');

    this.agentSearchTerm = '';
    this.supplierSearchTerm = '';
    this.brokerSearchTerm = '';
    this.vendorSearchTerm = '';
    this.stageSearchTerm = '';

    this.displayTextAgent = '';
    this.displayTextBroker = '';
    this.displayTextSupplier = '';
    this.displayTextVendor = '';
    this.displayTextStage = '';

    this.clearableBroker = false;
    this.clearableAgent = false;
    this.clearableSupplier = false;
    this.clearableVendor = false;
    this.clearableStage = false;

    this.selectedFilters.clear();
    this.selectedValue = '';
    this.displayText = '';
    this.clearable = false;
    this.showClear = false;
    this.showDropdown = false;
    this.updateButtonLabel();
    this.hideOptions();
    this.emitEvent();
  }

  hasActiveFilters(): boolean {
    return Object.keys(this.searchParams).length > 0;
  }

  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }

  closeTimeout: any;

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 4000); //  1 seconds
  }

  startCloseTimeout(): void {
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 500); //  1 seconds
  }

  cancelCloseTimeout(): void {
    this.showDropdown = true;
    clearTimeout(this.closeTimeout);
  }

  selectedFilter: 'all' | 'readed' | 'unread' = 'all';
  filteredNotes: any[] = [];
  notes: any[] = [];

  /****************** */

   selectOption(
    type: string,
    text: string,
    inputElement: HTMLInputElement
  ): void {
    this.selectedValue = text;
    this.displayText = text;
    this.optionsVisible = false;

    if (type === 'readed') {
      this.filterValues.action_required = true;
    } else if (type === 'unread') {
      this.filterValues.action_required = false;
    } else {
      this.filterValues.action_required = null;
    }

    this.clearable = true;
    this.selectedFilters.add('require_action');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }
  selectOption2(
    reference: string,
    name: string,
    filterType: string,
    inputElement: HTMLInputElement
  ): void {
    if (filterType === 'supplier') {
      this.selectedSupplier = reference;
      this.displayTextSupplier = name;
      this.optionsVisibleSupplier = false;
      this.filterValues.supplier = reference;
      this.selectedFilters.add('supplier');
      this.clearableSupplier = true;
    } else if (filterType === 'broker') {
      this.selectedBroker = reference;
      this.displayTextBroker = name;
      this.optionsVisibleBroker = false;
      this.filterValues.brokerReference = reference;
      this.clearableBroker = true;
      this.selectedFilters.add('broker');
    } else if (filterType === 'vendor') {
      this.selectedVendor = reference;
      this.displayTextVendor = name;
      this.optionsVisibleVendor = false;
      this.filterValues.vendor = reference;
      this.clearableVendor = true;
      this.selectedFilters.add('vendor');
    }
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  optionsVisibleStage: boolean = false;
  displayTextStage: string = '';
  stageSearchTerm = '';

  search(): void {
    this.searchParams = {};

    /* if (this.filterValues.action_required !== null) {
      this.searchParams['action_required'] = this.filterValues.action_required;
    } */
    if (this.filterValues.agent) {
      if (this.accountType == 'broker') {
        this.searchParams['broker_agent_id[eq]'] = `${this.filterValues.agent}`;
      }
      if (this.accountType == 'supplier') {
        this.searchParams[
          'supplier_agent_id[eq]'
        ] = `${this.filterValues.agent}`;
      }
    }

    if (this.filterValues.stage) {
      this.searchParams['stage[eq]'] = `${this.filterValues.stage}`;
    }

    if (this.filterValues.vendor) {
      this.searchParams[
        'vendor_reference_id[eq]'
      ] = `${this.filterValues.vendor}`;
    }

    if (this.filterValues.supplier) {
      this.searchParams[
        'supplier_reference_id[eq]'
      ] = `${this.filterValues.supplier}`;
    }

    if (this.filterValues.brokerReference) {
      this.searchParams[
        'broker_reference_id[eq]'
      ] = `${this.filterValues.brokerReference}`;
    }

    if (this.filterValues.broker) {
      this.searchParams[
        'broker_reference_id[eq]'
      ] = `${this.filterValues.broker}`;
    }

    this.localStorageService.setItem(
      'searchParamsNotes',
      JSON.stringify(this.searchParams)
    );

    this.emitEvent();
    this.showClear = true;
  }

  removeQueryParam(param: string): void {
    this.searchParams = { ...this.searchParams };
    delete this.searchParams[param];

    this.localStorageService.setItem(
      'searchParamsNotes',
      JSON.stringify(this.searchParams)
    );
    this.emitEvent();
  }

  /******* AGENT *******/

  searchAgent(term: string): void {
    if (this.ladata && this.ladata.agentData) {
      this.agentAccounts = this.ladata.agentData.filter((agentData: any) =>
        agentData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectAgent(agent: any, inputElement: HTMLInputElement) {
    this.displayTextAgent = agent.name + ' ' + agent.lastname;
    this.agentSearchTerm = agent.name + ' ' + agent.lastname;
    this.optionsVisibleAgent = false;
    this.clearableAgent = true;
    this.filterValues.agent = agent.reference;
    this.selectedFilters.add('agent');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* BROKER *******/

  searchBroker(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.brokerAccounts = this.ladata.brokerData.filter((brokerData: any) =>
        brokerData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectBroker(broker: any, inputElement: HTMLInputElement) {
    this.displayTextBroker = broker.name;
    this.brokerSearchTerm = broker.name;
    this.optionsVisibleBroker = false;
    this.clearableBroker = true;
    this.filterValues.broker = broker.reference;
    this.selectedFilters.add('broker');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* VENDOR *******/

  searchVendor(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.vendorAccounts = this.ladata.vendorData.filter((vendorData: any) =>
        vendorData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectVendor(vendor: any, inputElement: HTMLInputElement) {
    this.displayTextVendor = vendor.name;
    this.vendorSearchTerm = vendor.name;
    this.optionsVisibleVendor = false;
    this.clearableVendor = true;
    this.filterValues.vendor = vendor.reference;
    this.selectedFilters.add('vendor');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* SUPPLIER *******/

  filteredSupplier: string[] = [];

  searchSupplier(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.supplierAccounts = this.ladata.supplierData.filter(
        (supplierData: any) =>
          supplierData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectSupplier(supplier: any, inputElement: HTMLInputElement) {
    this.displayTextSupplier = supplier.name;
    this.supplierSearchTerm = supplier.name;
    this.optionsVisibleSupplier = false;
    this.clearableSupplier = true;
    this.filterValues.supplier = supplier.reference;
    this.selectedFilters.add('supplier');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******* STAGES *******/

  availableStages: string[] = [];
  filteredStages: any[] = [];

  searchStages(term: string): void {
    if (this.ladata) {
      this.filteredStages = this.ladata.stages.filter((stages: any) =>
        stages.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectStage(stage: any, inputElement: HTMLInputElement): void {
    this.displayTextStage = stage.name;
    this.stageSearchTerm = stage.name;
    this.optionsVisibleStage = false;
    this.filterValues.stage = stage.key;
    this.clearableStage = true;
    this.selectedFilters.add('stage');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /************** */

  preCargarFiltros(): void {
    if (this.searchParams['require_action']) {
      this.filterValues.action_required = this.searchParams['require_action'];
      this.stageSearchTerm = this.displayText;
      this.clearable = true;
      this.optionsVisible = false;
      this.selectedFilters.add('require_action');
    }

    if (this.searchParams['stage']) {
      this.filterValues.stage = this.searchParams['stage'];
      this.displayTextStage = this.ladata.stages.find(
        (stage: any) => stage.key === this.filterValues.stage
      ).name;
      this.stageSearchTerm = this.displayTextStage;
      this.clearableStage = true;
      this.optionsVisibleStage = false;
      this.selectedFilters.add('stage');
    }

    if (this.searchParams['broker_agent_id[eq]']) {
      this.filterValues.agent = this.searchParams['broker_agent_id[eq]'];
      this.displayTextAgent = this.ladata.agentData.find(
        (agent: any) => agent.reference === this.filterValues.agent
      ).name;
      this.clearableAgent = true;
      this.agentSearchTerm = this.displayTextAgent;
      this.selectedFilters.add('agent');
    }

    if (this.searchParams['supplier_reference_id[eq]']) {
      this.filterValues.supplier =
        this.searchParams['supplier_reference_id[eq]'];
      this.displayTextSupplier = this.ladata.supplierData.find(
        (supplier: any) => supplier.reference === this.filterValues.supplier
      ).name;
      this.clearableSupplier = true;
      this.supplierSearchTerm = this.displayTextSupplier;
      this.selectedFilters.add('supplier');
    }

    if (this.searchParams['vendor_reference_id[eq]']) {
      this.filterValues.vendor = this.searchParams['vendor_reference_id[eq]'];
      this.displayTextVendor = this.ladata.vendorData.find(
        (vendor: any) => vendor.reference === this.filterValues.vendor
      ).name;
      this.clearableVendor = true;
      this.vendorSearchTerm = this.displayTextVendor;
      this.selectedFilters.add('vendor');
    }

    if (this.searchParams['broker_reference_id[eq]']) {
      this.filterValues.broker = this.searchParams['broker_reference_id[eq]'];
      this.displayTextBroker = this.ladata.brokerData.find(
        (broker: any) => broker.reference === this.filterValues.broker
      ).name;
      this.clearableBroker = true;
      this.brokerSearchTerm = this.displayTextBroker;
      this.selectedFilters.add('broker');
    }

    this.updateButtonLabel();
    this.search();
  }
}
