import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private cookieKey = 'readNotifications';


  constructor(private cookieService: CookieService,private http: HttpClient) {}


  private apiUrl = environment.apiURL + 'notifications'; // URL del API



  getData(): Observable<any> {
    const url = `${this.apiUrl}`;
    return this.http.get(url);
  }

  updateStatus(reference: string, body: any): Observable<any> {
    return this.http.post(this.apiUrl + '/' + reference + '/read-status', body);
  }


  // Marcar una notificación como leída
  markAsRead(reference: string): void {

    this.http.post(this.apiUrl + '/' + reference + '/read-status',null);
    let readNotifications = this.getReadNotifications();
    if (!readNotifications.includes(reference)) {
      readNotifications.push(reference);
      this.cookieService.set(this.cookieKey, JSON.stringify(readNotifications));
    }
  }

  countUnreadNotifications(notifications: any[]): number {
    return notifications.filter(notification =>
      !this.isRead(notification.reference) && notification.read_status === 0
    ).length;
  }

  // Obtener todas las notificaciones leídas
  getReadNotifications(): string[] {
    const cookieValue = this.cookieService.get(this.cookieKey);
    return cookieValue ? JSON.parse(cookieValue) : [];
  }

  // Verificar si una notificación ya ha sido leída
  isRead(reference: string): boolean {
    return this.getReadNotifications().includes(reference);
  }
}
