import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private apiUrl = environment.apiURL + 'invoices'; // URL del API
  private apiUrlNotes = environment.apiURL + 'invoice-notes'; // URL del API
  private apiUrlLogs = environment.apiURL + 'invoice-logs'; // URL del API

  constructor(private http: HttpClient) {}

  getData(
    type: string = 'drafts',
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {}
  ): Observable<any> {
    var url = `${this.apiUrl}/stages/${type}`;
    if (type == '') {
      url = `${this.apiUrl}`;
    }

    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        ...params,
      },
    });
  }

  searchOrder(reference: string): Observable<any> {
    return this.http.get(this.apiUrl + '/' + reference);
  }

  getFilterData(
    type: string = 'drafts',
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {}
  ): Observable<any> {
    var url = `${this.apiUrl}/stages/${type}`;
    if (type == '') {
      url = `${this.apiUrl}`;
    }

    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        ...params,
      },
    });
  }

  getStages(): Observable<any> {
    return this.http.get(`${this.apiUrl}/stages`);
  }

  addData(item: any): Observable<any> {
    return this.http.post(this.apiUrl + '/new', item);
  }

  addNotes(item: any): Observable<any> {
    return this.http.post(this.apiUrlNotes + '/new', item);
  }

  getNotes(item: any): Observable<any> {
    return this.http.get(this.apiUrlNotes + '/invoice/' + item);
  }

  getTimeline(item: any): Observable<any> {
    return this.http.get(this.apiUrlLogs + '/timeline/' + item);
  }

  updateData(reference: string, body: any): Observable<any> {
    return this.http.put(this.apiUrl + '/' + reference + '/update', body);
  }

  getDataByReference(reference: string = ''): Observable<any> {
    const url = `${this.apiUrl}/${reference}`;
    return this.http.get(url);
  }
}
