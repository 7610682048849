<div class="not-found-container">
  <div class="text-container">
    <div class="img404">
      <img src="assets/images/logo-404-dark.png" class="img-fluid" alt="" height="22" />
    </div>
    <h1>{{ 'pages.help.how_can_we_help' | translate }}</h1>
    <h3 class="any-problem">{{ 'pages.help.any_problem' | translate }} <a class="contact-link"
        href="mailto: support&#64;digitalbuyersmanager.com">
        support&#64;digitalbuyersmanager.com</a></h3>

    <h5 class="mt-3">{{ 'pages.help.we_can_help' | translate }}</h5>
    <div class="go-home mt-3">
      <a href="/">{{ "pages.go_home" | translate }}
        <span><i class="icon-chevron-right"></i></span>
      </a>
    </div>
  </div>
</div>
