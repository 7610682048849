import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { LocalStorageService } from '../../../../core/services/localStorage/local-storage.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule, Location } from '@angular/common';
import { FormClientsOrdersComponent } from '../form-clients-orders.component';
import { TabsFormComponent } from '../../tabs-form/tabs-form.component';
import { ModelService } from '../../../../core/services/model/model.service';
import { OrdersService } from '../../../../core/services/orders/orders.service';
import { Title } from '@angular/platform-browser';

interface AccountData {
  reference: string;
  lastname: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any;
  status: boolean;
}
@Component({
  selector: 'app-form-add-orders',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule,
    FormClientsOrdersComponent,
    TabsFormComponent,
  ],
  templateUrl: './form-add-orders.component.html',
  styleUrl: './form-add-orders.component.css',
})
export class FormAddOrdersComponent {
  @Input() clientData?: any;
  @Input() ladata: any = [];
  @Input() client_reference?: any;
  @Output() emitSubmit = new EventEmitter<string>();
  account_type = this.localStorageService.getItem('account_type');
  @Input() tab?: any;
  @Input() openedFromClients = false;
  @Input() activeFormTab?: any;
  title = '';
  form: FormGroup = new FormGroup({});
  updatesTabs: any[] = [];
  draft = false;
  order_reference: string = '';
  stage?: any;
  newStage: string = '';
  @Output() backButtonClick2 = new EventEmitter<boolean>();
  role: any;

  back = '';
  next_ok = '';
  next_ko = '';

  agentData: AccountData[] = [];
  dealershipData: AccountData[] = [];
  organizationData: AccountData[] = [];
  supplierData: AccountData[] = [];
  @Input() formAddOrderOpened2?: boolean;
  @Input() clientName: string = '';
  @Input() formAddOrderOpened?: string;

  clientTitle: any;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private orderService: OrdersService,
    private location: Location,
    private titleService: Title,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      broker_id: ['', Validators.required],
      broker_agent: ['', Validators.required],
      broker_agent_id: ['', Validators.required],
      broker_reference: ['', Validators.required],
      notes: ['', Validators.required],
      stage: ['draft', Validators.required],
      supplier_id: ['', Validators.required],
      supplier_agent: ['', Validators.required],
      supplier_agent_id: ['', Validators.required],
      supplier_reference: ['', Validators.required],
      vendor_id: ['', Validators.required],
      vendor_quote_reference: ['', Validators.required],
      vendor_agent_id: ['', Validators.required],
      vendor_reference: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (this.formAddOrderOpened2) {
        /* console.log(this.clientName); */
        this.titleService.setTitle(`Cliente ${this.clientName} - DBM`);
      } else {
        this.titleService.setTitle(`Clientes - DBM`);
      }
      if (changes['ladata']) {
        this.role = this.localStorageService.getItem('role');
        this.agentData = this.ladata.agentData;
        this.vendorData = this.ladata.vendorData;
        this.brokerData = this.ladata.brokerData;
        this.supplierData = this.ladata.supplierData;
        if (this.account_type == 'broker' && !this.draft) {
        }
      }
    }
  }

  setVariables() {
    this.back = this.orderData?.back || '';
    this.next_ok = this.orderData?.next_ok;
    this.next_ko = this.orderData?.next_ko;
    this.stage = this.orderData?.stage;
    this.draft = this.orderData ? true : false;
    this.order_reference = this.orderData?.reference;
  }
  document_buttons_chico = false;

  openAddOrderForm2(client_reference: any, ladata: any) {
    this.ladata = ladata;
    this.client_reference = this.clientData.reference;
    this.document_buttons_chico = true;
  }

  emitBackButtonClick() {
    this.backButtonClick2.emit(true);
    this.document_buttons_chico = false;
    this.resetForm();
  }

  @Input() orderData?: any;

  vendorData: AccountData[] = [];
  brokerData: AccountData[] = [];

  changeStage(event: any) {
    this.newStage = event;
  }

  onSubmitOrderFast(stage: string) {
    this.emitSubmit.emit(stage);
  }

  copyToClipboard(value: string, event: Event): void {
    // Convertir el valor a mayúsculas y eliminar acentos
    const transformedValue = this.removeAccents(value.toUpperCase());

    // Crear un textarea para copiar el valor
    const textarea = document.createElement('textarea');
    textarea.value = transformedValue;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Añadir la clase para el estado copiado
    const button = event.currentTarget as HTMLElement;
    button.classList.add('copied');

    // Eliminar la clase después de 1 segundo
    setTimeout(() => {
      button.classList.remove('copied');
    }, 1000);
  }

  // Función para eliminar acentos
  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  onSubmit() {
    let formData = { ...this.form.value };
    formData.client_id = this.client_reference;
    formData.stage = 'draft';
    this.alertService.loading('swal_messages.please_wait_add_order');
    this.orderService.addData(formData).subscribe({
      next: (data) => {
        this.alertService
          .success('swal_messages.draft_order', 'swal_messages.awesome')
          .then((result) => {
            this.emitAddOrder();
            const nuevaURL = this.location.path();
            if (this.openedFromClients) {
              const index = nuevaURL.indexOf('/');
              console.log('Client reference en submit', this.client_reference);
              this.location.go(
                nuevaURL.substring(0, index) +
                  '/clients/' +
                  this.client_reference
              );
              this.resetForm();
              this.backButtonClick2.emit(true);
            }
          });
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      },
    });
  }

  emitAddOrder() {
    this.addOrder.emit(true);
  }
  @Output() addOrder = new EventEmitter<boolean>();

  resetForm(): void {
    this.form.reset({
      broker_id: '',
      broker_agent: '',
      broker_agent_id: '',
      broker_reference: '',
      notes: '',
      stage: '',
      supplier_id: '',
      supplier_agent: '',
      supplier_agent_id: '',
      supplier_reference: '',
      vendor_id: '',
      vendor_quote_reference: '',
      vendor_agent_id: '',
      vendor_reference: '',
    });
  }
}
