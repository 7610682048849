<div *ngIf="orderData.rules?.client_id =='w'">
  <form [formGroup]="clientForm">
    <!-- Client -->
    <div class="row">
      <div class="col-6">
        <div class="mb-5 buscador d-block">
          <label for="client" class="form-label">{{ "forms.inputs.client" | translate }}
            <span class="required">*</span></label>
          <div class="client-selector">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleClient }"
                [ngClass]="{ 'disabled-select': isClientInputDisabled}">
                <input type="text" (focus)="onFocus('client')" (blur)="hideOptions()"
                  placeholder="{{'forms.inputs.client' | translate }}" (input)="searchClients($event.target)"
                  [value]="displayTextClient" [disabled]="isClientInputDisabled" />
                <input type="hidden" formControlName="client_id" />
                <span class="icon-chevron-down arrow"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleClient" [ngClass]="{ visible: optionsVisibleClient }">
                <div class="option-value" *ngFor="let client of filteredClients" (mousedown)="
                  onSelectClient(client)
                ">
                  <div>
                    {{client.name}} {{client.lastname? client.lastname : ''}}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="client_selector_spinner" class="spinner-border text-info" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <label *ngIf="!orderData.client" for="client" class="text_add" (click)="openClientForm()">{{
            "forms.inputs.new_client" | translate }}
          </label>

        </div>
      </div>
    </div>
  </form>
</div>

<div class="row" *ngIf="orderData.client">
  <div class="col-6">
    <div class="column-list">
      <label>{{
        "forms.inputs.client" | translate
        }}</label>
      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled
          [value]="orderData.client?.name + ' ' + orderData.client?.lastname" #inputClientName />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputClientName.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <div class="input-group justify-content-end">
        <div class="clear">
          <button *ngIf="orderData.client?.assignee" class="btn btn-hover clear-button  ml-15" type="button"
            (click)="openAssigneeForm()">
            {{"forms.inputs.see_assignee" | translate }} {{orderData.client?.assignee.name}} <i
              class="icon-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="column-list">
      <label>{{
        "forms.inputs.identification" | translate
        }}</label>
      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.client?.tax_id" #inputFieldtax_id />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldtax_id.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="column-list">
      <label>{{ "forms.inputs.contact" | translate }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.client?.email" #inputFieldemail />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldemail.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <label>{{ "forms.inputs.phone" | translate }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.client?.phone" #inputFieldphone />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(extractPhoneNumber(inputFieldphone.value),$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="column-list">
      <label>{{
        "forms.inputs.direction" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.client?.billing_address"
          #inputFieldbilling_address />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_address.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>

      <label>{{
        "forms.inputs.billing_postal_code" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.client?.billing_postal_code "
          #inputFieldbilling_postal_code />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_postal_code.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <label>{{
        "forms.inputs.billing_state" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.client?.billing_state"
          #inputFieldbilling_state />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_state.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
      <label>{{
        "forms.inputs.billing_city" | translate
        }}</label>

      <div class="input-group div-copy">
        <input type="text" class="form-control" disabled [value]="orderData.client?.billing_city"
          #inputFieldbilling_city />

        <div class="input-group-append">
          <button class="btn btn-outline-secondary copy" type="button"
            (click)="copyToClipboard(inputFieldbilling_city.value,$event)">
            <i class="icon-copy"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
