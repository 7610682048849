import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FinancialDataService {
  private apiUrlFd = environment.apiURL + 'financial-data';

  constructor(private http: HttpClient) {}

  getUserPackDocuments(reference: string): Observable<any> {
    const url = `https://api.qa.belender.net/users/d9a9d2b5-0139-42ef-81a0-e8a29e8bddd4/pack-documents`;
    return this.http.get(url);
  }

  getFinancialStatus(): Observable<any> {
    const url = `${this.apiUrlFd}/status`;
    return this.http.get(url);
  }

  getFinancialDataRequest(requestId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlFd}/requests/${requestId}/fast-json`)
      .pipe(catchError(this.handleError));
  }

  getFinancialDataRequest2(clientReference: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlFd}/${clientReference}/data`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => new Error('Error fetching financial data.'));
  }

  requestClavePin(clientReference: string): Observable<any> {
    const body = {
      client_id: clientReference,
    };
    return this.http
      .post(`${this.apiUrlFd}/pre-request/clave-pin-sms`, body)
      .pipe(catchError(this.handleError));
  }
  // financial-data.service.ts
  /*   requestClavePin(clientReference: string): Observable<any> {
    const body = {
      reference: clientReference,
    };

    return this.http
      .post(`${this.apiUrlFd}/pre-request/clave-pin-sms`, body)
      .pipe(
        catchError((error: any) => {
          console.error('Error fetching financial data:', error.error.text);
          return throwError(() => new Error('Error fetching financial data.'));
        })
      );
  } */

  getClientRequests(clientReference: string): Observable<any> {
    return this.http
      .get(`${this.apiUrlFd}/client/${clientReference}/requests`)
      .pipe(catchError(this.handleError));
  }
}
