import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatNativeDateModule,
} from '@angular/material/core';


@Component({
  selector: 'app-tabs-form',
  standalone: true,
  imports: [ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule],
  templateUrl: './tabs-form.component.html',
  styleUrl: './tabs-form.component.css'
})
export class TabsFormComponent {

  @Output() changeForm = new EventEmitter<any>();

  @Input() updatesTabs:any = [];

  @Input() activeForm = 'general';
  @Input() role = 'Admin';
  @Input() draft = false;
  @Input() document_buttons_chico = false;

  oldForm = 'general';

  emitchangeForm($event : any) : void {
    this.oldForm = this.activeForm;
    this.changeForm.emit($event);
  }



}
