<div class="d-flex">
  <div class="card w-100 form-right">
    <div class="back">
      <h2 class="edit-client">
        {{ "forms.names.assignee" | translate }} {{assignee?.name + ' ' + assignee?.lastname ? assignee?.name + ' ' + assignee?.lastname : assignee?.name}}
      </h2>
      <i class="icon-cross2" (click)="emitBackButtonClick()"></i>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form">
            <div *ngIf="assignee" class="row">
              <div class="col-6">
                <div class="column-list">
                  <label>{{
                    "forms.inputs.client" | translate
                    }}</label>
                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled
                      [value]="assignee?.name + ' ' + assignee?.lastname" #inputClientName />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputClientName.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="column-list">
                  <label>{{
                    "forms.inputs.identification" | translate
                    }}</label>
                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled [value]="assignee?.tax_id"
                      #inputFieldtax_id />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputFieldtax_id.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="column-list">
                  <label>{{ "forms.inputs.contact" | translate }}</label>

                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled [value]="assignee?.email"
                      #inputFieldemail />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputFieldemail.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>
                  <label>{{ "forms.inputs.phone" | translate }}</label>

                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled [value]="assignee?.phone"
                      #inputFieldphone />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputFieldphone.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="column-list">
                  <label>{{
                    "forms.inputs.direction" | translate
                    }}</label>

                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled [value]="assignee?.billing_address"
                      #inputFieldbilling_address />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputFieldbilling_address.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>

                  <label>{{
                    "forms.inputs.billing_postal_code" | translate
                    }}</label>

                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled
                      [value]="assignee?.billing_postal_code " #inputFieldbilling_postal_code />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputFieldbilling_postal_code.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>
                  <label>{{
                    "forms.inputs.billing_state" | translate
                    }}</label>

                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled [value]="assignee?.billing_state"
                      #inputFieldbilling_state />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputFieldbilling_state.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>
                  <label>{{
                    "forms.inputs.billing_city" | translate
                    }}</label>

                  <div class="input-group div-copy">
                    <input type="text" class="form-control" disabled [value]="assignee?.billing_city"
                      #inputFieldbilling_city />

                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary copy" type="button"
                        (click)="copyToClipboard(inputFieldbilling_city.value,$event)">
                        <i class="icon-copy"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-section">
                <div class="form-section-documents" *ngIf="assignee.documents">
                  <label class="form-label">{{
                    "forms.labels.exist_documents_client" | translate
                  }}</label>
                  <!-- Botón para expandir/contraer la lista -->
                  <div
                    (click)="showExistingDocuments = !showExistingDocuments"
                    style="cursor: pointer"
                  >
                    <i
                      class=""
                      [ngClass]="{
                        'icon-chevron-up': showExistingDocuments,
                        'icon-chevron-down': !showExistingDocuments
                      }"
                    ></i>
                  </div>
                </div>

                <!-- Lista de documentos existentes -->
                <ul *ngIf="showExistingDocuments">
                  <div
                    class="form-section-documents"
                    *ngFor="let existingDocument of assignee.documents"
                  >
                    <a [href]="existingDocument.file" [download]="existingDocument.name"  target="_blank">
                      <li class="existDocument">
                        <img
                          class="thumbnail"
                          [src]="getThumbnailSrc2(existingDocument.name)"
                          alt="Thumbnail"
                        />
                        <i class="icon-download2"></i>
                        <div class="type" [ngStyle]="{'background-color': pickAColor(existingDocument.type)}">{{ "select.document_type." + existingDocument.type | translate }}</div>
                        {{ existingDocument.name }}
                      </li>
                    </a>
                  </div>
                </ul>
              </div>

            </div>


        </div>
      </div>
      <div class="div-form"></div>
    </div>
    <!-- end card body -->
  </div>
</div>
