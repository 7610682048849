<div class="row d-flex justify-content-between align-items-center">
  <div class="col-6">
    <!-- <label for="organization_id" class="form-label">{{ "invoice.ordersAdded" | translate }} </label> -->
      <label *ngIf="selectedItems.length > 0" class="form-label"> {{selectedItems.length }} {{ "invoice.orderSelected" | translate }} <span class="delete_span" (click)="onSubmit()"> {{ "invoice.delete" | translate }} </span></label>

  </div>
  <div class="col-6">
    <div class="btn-izq">
      <button class="btn btn-hover" type="button" (click)="OpenFormAddOrders()">
        {{ "buttons.add+" | translate }} <i class="icon-plus"></i>
      </button>
    </div>
  </div>
</div>

<!-- Contenedor para la tabla -->
<div class="table-wrapper">
  <div class="table-container" #scrollableTable (scroll)="checkScroll($event)">
    <!-- Encabezados de la tabla -->
    <table>
      <thead>
        <tr>
          <th class="col-10">
            <input type="checkbox" (change)="toggleSelectAll($event)" [checked]="areAllSelected()">
          </th> <!-- Columna vacía para el checkbox -->
          <th class="col-20">{{ "invoice.order" | translate }}</th>
          <th class="col-20">{{ "invoice.vehicle" | translate }}</th>
          <th class="col-20">{{ "invoice.vendor" | translate }}</th>
          <!-- <th class="col-20">{{ "invoice.inInvoice" | translate }}</th> -->
          <th class="col-10">{{ "invoice.commission" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td class="col-10">
            <input type="checkbox" (change)="toggleSelection(item)" [checked]="isSelected(item)">
          </td>
          <td class="col-20">{{ item.broker_reference }}</td>
          <td class="col-20">{{ item.vehicle }} - <span class="badge text-badge"
              [ngClass]="getBadgeColor(item.vehicle_status,item.vehicle_status)">{{ "badge." + item.vehicle_status
              |
              translate }}</span> </td>
          <td class="col-20">{{ item.vendor }}</td>
          <!-- <td class="col-20">
            <ng-container *ngIf="item.invoices && item.invoices.length > 0">
              - {{ "invoice.inInvoices" | translate }} -
              <span *ngFor="let invoice of item.invoices; let i = index">
                <span class="badge text-badge" [ngClass]="getBadgeColor(invoice,'invoice')">
                  {{ invoice.serial }} {{ invoice.number }}
                </span>
                <ng-container *ngIf="i < item.invoices.length - 1">,</ng-container>
              </span>
            </ng-container>
          </td> -->
          <td class="col-10">{{ item.broker_commission_total }} € <ng-container
              *ngIf="item.invoices && item.invoices.length > 0">
              <span *ngFor="let invoice of item.invoices; let i = index">
                <span class="badge text-badge" [ngClass]="getBadgeColor(invoice,'invoice')">
                  {{ invoice.serial }} {{ invoice.number }}
                </span>
                <ng-container *ngIf="i < item.invoices.length - 1"></ng-container>
              </span>
            </ng-container></td>
        </tr>
      </tbody>
    </table>
    <div class="scroll-indicator" *ngIf="showScrollIndicator">
      <i class="fas fa-chevron-down"></i>
    </div>
  </div>
</div>

<!-- Contenedor para el total fuera del scroll -->
<div class="total-container">
  <span>{{ "invoice.totalComission" | translate }} </span><span> : {{ totalComision }} €</span>
</div>

<!-- <div class="row" *ngIf="selectedItems.length > 0">
  <div class="col-12">
    <div class="mb-3">
      <div class="btn-izq">
        <button class="btn btn-hover" type="button" (click)="onSubmit()">
          {{ "buttons.deleteSelected" | translate }} <i class="icon-trash"></i>
        </button>
      </div>
    </div>
  </div>
</div> -->
