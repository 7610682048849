import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-setting',
  standalone: true,
  imports: [CommonModule, RouterOutlet,RouterLink, TranslateModule],

  templateUrl: './setting.component.html',
  styleUrl: './setting.component.css',
})
export class SettingComponent {
  botones = ['settings.buttons.general'];
  botonSeleccionado = 0;
  opt = false;

  seleccionarBoton(index: number) {
    this.botonSeleccionado = index;
    this.opt = false;
  }

  changeOpt() {
    this.opt = !this.opt;
  }
}
