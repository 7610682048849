<form [formGroup]="form">
  <div class="form-section">
    <div class="row">
      <div class="mb-3">
        <label for="content" class="form-label">{{
          "forms.inputs.message" | translate
          }}</label>
        <textarea class="form-control textarea300" id="content" rows="5" formControlName="content"></textarea>
      </div>
    </div>
  </div>
</form>
<div class="contenedor">
  <div class="timeline-container">

    <div *ngFor="let itemBig of notes">
      <div class="div-date">
        <span class="date">{{ itemBig.date | date : "mediumDate" }}</span>
      </div>
      <div *ngFor="let item of itemBig.notes">
        <div class="timeline-item d-flex" [ngClass]="{ 'reverse': item.created_by == userName}">
          <div class="div-details-notes d-flex flex-column">

            <div class="d-flex align-items-center">
              <div class="details">
                <ul class="no-bullets">
                  <div *ngIf="item.message == 'notes_risk'">
                    <!-- <span class="pipe-second">{{ "pipe.notes_risk" |
                      translate}} </span> -->
                      <span>{{item.values}}</span> </div>
                  <div *ngIf="item.message == 'notes'">
                    <!-- <span class="pipe-second">{{ "pipe.notes2" | translate}}
                    </span> -->
                    <span>{{item.values}}</span> </div>
                </ul>
                <span class="pipe-second fs12"><i class="icon-user"></i> {{item.created_by}}  <i class="icon-clipboard-empty"> </i> {{ item.time }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
