<form [formGroup]="generalForm">



  <!-- Vendor,sup,bro,dealer -->
  <div class="form-section">
    <div class="row">
      <div class="col-6">
        <div class="form-section">
          <div class="mb-3">
            <label for="serial" class="form-label">{{
              "forms.inputs.serial" | translate
              }}</label>
            <div class="input-group div-copy">
              <input type="text" class="form-control" id="serial" #inputBrokerReference formControlName="serial"
                placeholder="{{
                  'forms.inputs.serial' | translate
                }}" required />
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-section">
          <div class="mb-3">
            <label for="number" class="form-label">{{
              "forms.inputs.number" | translate
              }}</label>
            <div class="input-group div-copy">
              <input type="text" class="form-control" id="number" #inputBrokerReference formControlName="number"
                placeholder="{{
                  'forms.inputs.number' | translate
                }}" required />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-4">
      <div class="form-section">
        <div class="mb-3">
          <label for="account_id" class="form-label">{{
            "forms.inputs.account" | translate
            }}</label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="account_id" #inputBrokerReference formControlName="account_id"
              placeholder="{{
                'forms.inputs.reference' | translate
              }}" required />
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-6">
      <div class="form-section">
        <div class="mb-4">
          <label for="organization_id" class="form-label">{{
            "forms.inputs.organization" | translate
            }}</label>
          <select id="organization_id" class="form-control" formControlName="organization_id">
            <option value="" disabled selected>
              {{ "forms.inputs.select_option" | translate }}
            </option>
            <option *ngFor="let org of organizationData" [value]="org.reference">
              {{ org.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-section">
        <div class="mb-4">
          <label for="dealership_id" class="form-label">{{
            "forms.inputs.dealership" | translate
            }}</label>
          <select id="dealership_id" class="form-control" formControlName="dealership_id">
            <option value="" disabled selected>
              {{ "forms.inputs.select_option" | translate }}
            </option>
            <option *ngFor="let dealership of dealershipData" [value]="dealership.reference">
              {{ dealership.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="draft">
      <div class="row linea"></div>
    <div class="form-section">
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="invoiced_at" class="form-label">{{ "forms.inputs.invoiced_at"
              | translate }}
            </label>
            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="invoiced_at" formControlName="invoiced_at" placeholder="{{
                'forms.inputs.invoiced_at' | translate
              }}" />
              <mat-datepicker-toggle matSuffix [for]="invoiced_at"></mat-datepicker-toggle>
              <mat-datepicker #invoiced_at></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="paid_at" class="form-label">{{ "forms.inputs.paid_at"
              | translate }}
            </label>
            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="paid_at" formControlName="paid_at" placeholder="{{
                'forms.inputs.paid_at' | translate
              }}" />
              <mat-datepicker-toggle matSuffix [for]="paid_at"></mat-datepicker-toggle>
              <mat-datepicker #paid_at></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="draft">
    <div class="form-section">
      <label for="stage" class="form-label">{{ "forms.inputs.stage" | translate }}
        <span class="required">*</span></label>
      <div class="row">
        <div class="col-6">
          <div class="mb-3">

            <select id="stage" class="form-control" formControlName="stage" (ngModelChange)="changeStage($event)">
              <option [value]="invoiceData.stage" selected>
                {{
                "forms.inputs.status_select." + invoiceData.stage
                | translate
                }}
              </option>
              <option *ngFor="let type of invoiceData.stages" [value]="type" [selected]="type === invoiceData.stage">
                {{
                "forms.inputs.status_select." + type | translate
                }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="btn-next">
            <button *ngIf="next_ok" type="submit" class="btn btn-primary btn-next_ok"
              (click)="onSubmitOrderFast(next_ok)"> <i class="icon-next-circle"></i>
              {{ "forms.inputs.status_select."+ next_ok | translate }}
            </button>

            <button *ngIf="next_ko" type="submit" class="btn btn-primary btn-next_ko"
              (click)="onSubmitOrderFast(next_ko)"> <i class="icon-pause-circle"></i>
              {{ "forms.inputs.status_select."+ next_ko | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label for="notes" class="form-label">{{
            "forms.inputs.addnote" | translate
            }}</label>
          <textarea class="form-control textarea150" id="notes" rows="5" formControlName="notes"></textarea>
        </div>
      </div>
    </div>
  </div>
</form>
