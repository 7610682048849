<div class="row m-0 h-100">

  <div class="form" *ngIf="!isDisabled">
    <div class="form-section">
      <div class="form-section-documents" *ngIf="existingDocuments">
        <label class="form-label">{{
          "forms.labels.exist_documents_" + label | translate
          }} <!-- <a class="goDocs" (click)="emitgoDocs()"> <span class="goDocs">{{
              "forms.labels.download_all"| translate
              }}</span></a> --></label>
        <!-- Botón para expandir/contraer la lista -->
        <div (click)="showExistingDocuments = !showExistingDocuments" style="cursor: pointer">
          <i class="" [ngClass]="{
              'icon-chevron-up': showExistingDocuments,
              'icon-chevron-down': !showExistingDocuments
            }"></i>
        </div>
      </div>

      <!-- Lista de documentos existentes -->
      <ul *ngIf="showExistingDocuments">
        <div class="form-section-documents" *ngFor="let existingDocument of existingDocuments">
          <a [href]="existingDocument.file" [download]="existingDocument.name" target="_blank">
            <li class="existDocument">
              <img class="thumbnail" [src]="getThumbnailSrc2(existingDocument.name)" alt="Thumbnail" />
              <i class="icon-download2"></i>
              <div class="type" [ngStyle]="{'background-color': pickAColor(existingDocument.type)}">{{
                "select.document_type." + existingDocument.type | translate }}</div>
              {{ existingDocument.name + " "}} <span class="secondary-text"> ({{ existingDocument.created_at | date:
                'dd/MM/yyyy' }}) </span>
            </li>
          </a>
        </div>
      </ul>
    </div>
    <div *ngIf="uploadFile">
      <div class="form-section">
        <div class="drop-zone" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
          (click)="fileInput.click()">
          {{ "forms.labels.draganddrop" | translate }}
          <input #fileInput type="file" multiple style="display: none" (change)="onFileInputChange($event)" />
        </div>
      </div>
      <ul *ngIf="files.length > 0">
        <li *ngFor="let file of files; let i = index">
          <div class="listFiles">
            <div class="div-thumbnail">
              <img class="thumbnail" [src]="getThumbnailSrc(file.file)" alt="Thumbnail" />
              {{ file.file.name }}
              <span (click)="removeFile(i)">
                <i class="icon-trash"></i>
              </span>
            </div>
            <div class="">
              <select class="select-option form-control" (change)="selectOptionChanged($event, i)">
                <option value="defaultOption">
                  {{ "forms.inputs.type" | translate }}
                </option>
                <option *ngFor="let type of selectTypes | filterSelectTypes : varSelectType" [value]="type.key"
                  [selected]="type.key === file.selectOption">
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
        </li>
      </ul>
    <!--   <div class="btn-upload" *ngIf="files.length > 0">
        <button type="submit" class="btn btn-primary btn-primary-form" (click)="onSubmit()">
          {{ "invoice.upload_" + label | translate }}
          <i class="icon-upload2"></i>
        </button>
      </div> -->
    </div>
  </div>
