<div>
  <div class="mb-3 buscador">
    <div class="input-group form-control">
      <i class="icon-magnifier" aria-hidden="true"></i>
      <input [(ngModel)]="searchParams.search" type="text" placeholder="{{ 'buttons.invoice-filter' | translate }}"
        aria-label="Buscar" matInput (keyup)="keyup($event)" class="placeholder-filter text" />
    </div>

    <div>
      <button class="btn btn-hover filter" type="button" (click)="toggleDropdown()">
        {{ filterButtonLabel }}
        <i class="icon-equalizer"></i>
        <!-- <i class="fas fa-filter" aria-hidden="true"></i> -->
      </button>

      <div *ngIf="showDropdown" class="dropdown-content" role="menu" (mouseenter)="cancelCloseTimeout()"
        (mouseleave)="startCloseTimeout()">
        <div class="filter-content">
          <div class="clear-filter mb-3">
            <div>{{ "buttons.filter" | translate }}</div>
          </div>

          <!-- FILTER -->

          <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{'expanded': optionsVisibleType}">
                <input #typeInput type="text" (focus)="onFocus('type')" (blur)="hideOptions()"
                  placeholder="{{ 'forms.inputs.type' | translate }}" [value]="displayTextType" readonly class="text" />
                <span *ngIf="clearable" class="clear-icon"
                  (click)="clearSelectedOption('type'); $event.stopPropagation();"><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleType" [ngClass]="{ visible: optionsVisibleType }">
                <div class="option-value" (mousedown)="selectOption('standar', 'Normal', typeInput)">
                  <div>{{ 'forms.inputs.standar' | translate }}</div>
                </div>
                <div class="option-value" [ngClass]="{'selected': selectedValue === 'corrective'}"
                  (mousedown)="selectOption('corrective', 'Rectificativa', typeInput)">
                  <div>{{ 'forms.inputs.corrective' | translate }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleDealership }">
                <input class="text" #dealershipInput type="text" (focus)="onFocus('dealership')" (blur)="hideOptions()"
                  [(ngModel)]="dealershipSearchTerm" placeholder="Concesión"
                  (input)="searchDealership(dealershipSearchTerm)" [value]="displayTextDealership" />
                <span *ngIf="clearableDealer" class="clear-icon" (click)="
                                                        clearSelectedOption('dealership'); $event.stopPropagation()
                                                      "><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleDealership"
                [ngClass]="{ visible: optionsVisibleDealership }">
                <div class="option-value" *ngFor="let dealership of filteredDealership"
                  (mousedown)="onSelectDealership(dealership, dealershipInput)">
                  <div>{{ dealership.name }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="main-selector-container">
              <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleOrganization }">
                <input class="text" #organizationInput type="text" (focus)="onFocus('organization')"
                  (blur)="hideOptions()" [(ngModel)]="organizationSearchTerm" placeholder="Empresa "
                  (input)="searchOrganizations(organizationSearchTerm)" [value]="displayTextOrganization" />
                <span *ngIf="clearableOrganization" class="clear-icon" (click)="
                                    clearSelectedOption('organization'); $event.stopPropagation()
                                  "><i class="icon-cross2"></i></span>
                <span class="icon-chevron-down"></span>
              </div>
              <div class="options-container" *ngIf="optionsVisibleOrganization"
                [ngClass]="{ visible: optionsVisibleOrganization }">
                <div class="option-value" *ngFor="let organization of filteredOrganizations"
                  (mousedown)="onSelectOrganization(organization, organizationInput)">
                  <div>{{ organization.name }}</div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>


    <div class="form-check require-action">
      <input #requireActionCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
        (change)="onCheckboxChange($event)">
      <label class="form-check-label" for="flexCheckDefault">
        {{ "buttons.require-action" | translate }}
      </label>
    </div>

    <div *ngIf="showClear && hasActiveFilters()" class="clear">
      <button class="btn btn-hover clear-button" type="button" (click)="clearFilter($event)">
        {{ "buttons.clearFilter" | translate }}
      </button>
    </div>

  </div>

</div>