<div class="encabezado">
  <div class="col-4 getBack "> <a routerLink="/settings" class="getBack"> <span><i class="icon-chevron-left"></i>{{ "forms.buttons.back" | translate }} </span></a></div>
  <div class="">
    <div class="">
      <div>
        <h5 class="titulo1">{{ "pages.titles.automations" | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="row">
   <div class="col-4"></div>
    <div class="col-8">
      <div class="btn-izq">
        <button class="btn btn-hover" type="button" (click)="toggleForm()">
          {{ "buttons.add+" | translate }} <i class="icon-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="tabla">
    <table
      mat-table
      class="full-width-table"
      matSort
      aria-label="Brands"
      [dataSource]="dataSource"
    >
      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cabecera-tabla"
        >
          {{ "tables.columns.rule_name" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cabecera-tabla"
        >
          {{ "tables.columns.rule_descr" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
      </ng-container>
      <!-- events Column -->
      <ng-container matColumnDef="events">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cabecera-tabla"
        >
          {{ "tables.columns.event" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ "automation.event." + row.events | translate }}
        </td>
      </ng-container>
      <!-- conditions Column -->
      <ng-container matColumnDef="conditions">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cabecera-tabla"
        >
          {{ "tables.columns.condition" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="column-list">
            <span *ngFor="let condition of row.conditions"
              >{{ "automation.conditions." + condition.condition | translate }}:
              {{ condition.value }}</span
            >
          </div>
        </td>
      </ng-container>
      <!-- actions Column -->
      <ng-container matColumnDef="actions">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cabecera-tabla"
        >
          {{ "tables.columns.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="column-list">
            <!--  <span> {{ row.actions }}</span> -->
            <span *ngFor="let action of row.actions"
              >{{ "automation.actions." + action.action | translate }}:
              {{ action.value }}</span
            >
          </div>
        </td>
      </ng-container>
       <!-- enabled Column -->
       <ng-container matColumnDef="enabled">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cabecera-tabla"
        >
          {{ "tables.columns.enabled" | translate }}
        </th>
        <td mat-cell *matCellDef="let row"> <span *ngIf="row.enabled" class="badge bg-info" >{{
          "forms.inputs.enabled" | translate
          }}</span><span *ngIf="!row.enabled" class="badge bg-warning" >{{
          "forms.inputs.disabled" | translate
          }}</span></td>

      </ng-container>
      <!-- rowActions Column -->
      <ng-container matColumnDef="rowActions">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cabecera-tabla"
        >
          {{ "tables.columns.actions" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <i class="icon-pencil text-success icon-actions" (click)="getAutomation(row.reference)"></i>
          <i class="icon-trash2 text-danger icon-actions" (click)="deleteAutomation(row.reference)"></i>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [length]="dataSource.data.length"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</div>
<div
  class="formulario-derecha-container"
  [ngClass]="{ 'mostrar-formulario': showForm }"
>
  <app-form-add-automation
    [automationData]="automationData"
    (backButtonClick)="cerrarFormulario()"
    (addAutomation)="refrescar()"
  ></app-form-add-automation>
</div>
<div class="overlay" *ngIf="showForm"></div>
