import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsTypeService {
  private apiUrl = environment.apiURL+'document-types'; // URL del API

  constructor(private http: HttpClient) {}

  decodeType(opt: any, item: any): Observable<any> {
    return this.http.get(this.apiUrl + '/decode/' + opt + '/' + item);
  }

  getSelectType(): Observable<any> {
    return this.http.get(this.apiUrl);
  }
}
