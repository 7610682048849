import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Client, Orders } from '../../../core/interfaces/orders';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent implements OnChanges {
  @ViewChild('requireActionCheckbox') requireActionCheckbox!: ElementRef;
  @Output() filterChanged = new EventEmitter<any>();
  @Input() ladata: any = [];
  @Output() clearSearch = new EventEmitter<void>();
  submenuOpen: boolean = false;
  closeTimeout: any;

  dataSource = new MatTableDataSource<Orders>();
  orders: any[] = [];
  preventFocus: boolean = false;
  brandSearchTerm: string = '';

  organizationSearchTerm: string = '';
  dealershipSearchTerm: string = '';

  agentSearchTerm: string = '';
  displayTextBrand: string = '';
  optionsVisibleBrand: boolean = false;
  searchParams: any = {};

  showDropdown = false;
  showClear = false;

  searchTerm = '';
  stageSearchTerm = '';
  clientSearchTerm = '';
  brokerSearchTerm = '';
  supplierSearchTerm = '';
  vendorSearchTerm = '';
  statusSearchTerm = '';
  searchText = '';

  clearableOrganization = false;
  clearableDealer = false;

  accountType: string | null = null;

  autoFetch: any;

  filterValues: {
    search: string;
    type: string | null;
    require_action: boolean | null;
    organization: string | null;
    dealership: string | null;
  } = {
    search: '',
    type: null,
    require_action: null,
    organization: null,
    dealership: null,
  };

  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  ngAfterViewInit() {
    this.searchTerms
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.search();
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['filter']) {
        let filter = params['filter'];
        if (filter) {
          this.localStorageService.setItem('searchParamsInvoice', filter);
        }
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ladata'] && changes['ladata'].currentValue) {
      if (this.ladata.flag) {
        this.checkFilters();
        this.fetchAllData();
      } else {
        this.fetchAllData();
      }
    }
  }

  private fetchAllData() {
    Promise.all([this.searchOrganizations(''), this.searchDealership('')]).then(
      () => {}
    );
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 4000); //  1 seconds
  }

  startCloseTimeout(): void {
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 500); //  1 seconds
  }

  cancelCloseTimeout(): void {
    this.showDropdown = true;
    clearTimeout(this.closeTimeout);
  }

  /****** Type ******/

  optionsVisibleOrganization: boolean = false;
  optionsVisibleDealership: boolean = false;

  displayTextOrganization: string = '';
  displayTextDealership: string = '';

  selectedSupplier: string = '';
  selectedBroker: string = '';
  selectedVendor: string = '';
  selectedOrganization: string = '';
  selectedDealership: string = '';

  selectedValue: string = '';
  displayTextType: string = '';
  clearable = false;
  optionsVisibleType = false;

  preCargarFiltros(): void {
    if (this.searchParams['search']) {
      this.filterValues.search = this.searchParams['search'];
      this.searchTerms.next(this.filterValues.search);
    } else {
      this.searchTerms.next('');
    }

    this.filterValues.type = this.searchParams['type'] ?? null;
    this.selectedValue = this.filterValues.type ?? '';

    if (this.searchParams['dealership_reference_id[eq]']) {
      this.filterValues.dealership =
        this.searchParams['dealership_reference_id[eq]'];
      this.displayTextDealership = this.ladata.dealershipData.find(
        (dealership: any) =>
          dealership.reference === this.filterValues.dealership
      ).name;
      this.clearableDealer = true;
      this.dealershipSearchTerm = this.displayTextDealership;
      this.selectedFilters.add('dealership');
    }

    if (this.searchParams['organization_reference_id[eq]']) {
      this.filterValues.organization =
        this.searchParams['organization_reference_id[eq]'];
      this.displayTextOrganization = this.ladata.organization.find(
        (organization: any) =>
          organization.reference === this.filterValues.organization
      ).name;
      this.clearableOrganization = true;
      this.organizationSearchTerm = this.displayTextOrganization;
      this.selectedFilters.add('organization');
    }

    this.filterValues.type = this.searchParams['type'] ?? null;
    this.selectedValue = this.filterValues.type ?? '';

    switch (this.filterValues.type) {
      case 'standar':
        this.displayTextType = 'Normal';
        break;
      case 'corrective':
        this.displayTextType = 'Rectificativa';
        break;
      default:
        this.displayTextType = '';
    }

    this.clearable = !!this.filterValues.type;
    this.selectedFilters[this.filterValues.type ? 'add' : 'delete']('type');
    this.updateButtonLabel();
    this.search();
  }

  onFocus(filterType: string): void {
    this.hideOptions();
    switch (filterType) {
      case 'organization':
        this.optionsVisibleOrganization = true;
        break;
      case 'type':
        this.optionsVisibleType = true;
        break;
      case 'dealership':
        this.optionsVisibleDealership = true;
        break;
    }
  }

  /********  INPUT  **********/

  keyup(event: KeyboardEvent) {
    this.filterValues.search = (event.target as HTMLInputElement).value;
    this.searchTerms.next(this.filterValues.search);
  }

  removeQueryParam(param: string): void {
    this.searchParams = { ...this.searchParams };
    delete this.searchParams[param];

    this.localStorageService.setItem(
      'searchParamsClients',
      JSON.stringify(this.searchParams)
    );

    this.emitEvent();
  }

  /********  FILTER **********/

  checkFilters(): void {
    if (this.localStorageService.getItem('searchParamsInvoice')) {
      this.searchParams = JSON.parse(
        this.localStorageService.getItem('searchParamsInvoice') || '{}'
      );
      if (Object.keys(this.searchParams).length !== 0) {
        this.preCargarFiltros();
      }
    }
  }

  stages: any[] = [];
  supplierAccounts: any[] = [];
  brokerAccounts: any[] = [];
  vendorAccounts: any[] = [];
  agentAccounts: any[] = [];

  selectOption(type: any, text: string, inputElement: HTMLInputElement): void {
    this.selectedValue = text;
    this.displayTextType = text;
    this.optionsVisibleType = false;
    this.filterValues.type = type;
    this.clearable = true;
    this.selectedFilters.add('type');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  /******** ORGANIZATION FILTER **********/

  filteredOrganizations: any[] = [];

  searchOrganizations(term: string): void {
    if (this.ladata && this.ladata.organization) {
      this.filteredOrganizations = this.ladata.organization.filter(
        (organization: any) =>
          organization.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectOrganization(organization: any, organizationInput: HTMLInputElement) {
    this.displayTextOrganization = organization.name;
    this.organizationSearchTerm = organization.name;
    this.optionsVisibleOrganization = false;
    this.filterValues.organization = organization.reference;
    this.clearableOrganization = true;
    this.selectedFilters.add('organization');
    this.updateButtonLabel();
    this.search();
    organizationInput.blur();
  }

  /******** DEALERSHIP FILTER **********/

  filteredDealership: any[] = [];

  searchDealership(term: string): void {
    if (this.ladata && this.ladata.dealershipData) {
      this.filteredDealership = this.ladata.dealershipData.filter(
        (dealership: any) =>
          dealership.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectDealership(dealership: any, dealershipInput: HTMLInputElement) {
    this.displayTextDealership = dealership.name;
    this.dealershipSearchTerm = dealership.name;
    this.optionsVisibleDealership = false;
    this.filterValues.dealership = dealership.reference;
    this.clearableDealer = true;
    this.selectedFilters.add('dealership');
    this.updateButtonLabel();
    this.search();
    dealershipInput.blur();
  }

  /*******Filters label*******/

  hideOptions(): void {
    this.optionsVisibleType = false;
    this.optionsVisibleOrganization = false;
    this.optionsVisibleDealership = false;
  }

  selectedFilters: Set<string> = new Set();

  updateButtonLabel() {
    const filterCount = this.selectedFilters.size;
    this.showClear = filterCount > 0;
  }

  get filterButtonLabel() {
    const filterCount = this.selectedFilters.size;
    return filterCount > 0 ? `Filtrar (${filterCount})` : 'Filtrar';
  }

  clearSelectedOption(filter: string): void {
    switch (filter) {
      case 'require_action':
        this.selectedFilters.delete('require_action');
        this.filterValues.require_action = null;
        this.requireActionCheckbox.nativeElement.checked = false;
        break;

      case 'organization':
        this.displayTextOrganization = '';
        this.organizationSearchTerm = '';
        this.clearableOrganization = false;
        this.selectedFilters.delete('organization');
        this.filterValues.organization = null;
        break;

      case 'type':
        this.displayTextType = '';
        this.organizationSearchTerm = '';
        this.clearable = false;
        this.selectedFilters.delete('type');
        this.filterValues.type = null;
        break;
      case 'dealership':
        this.displayTextDealership = '';
        this.dealershipSearchTerm = '';
        this.clearableDealer = false;
        this.selectedFilters.delete('dealership');
        this.filterValues.dealership = null;
        break;
    }
    this.updateButtonLabel();
    this.search();

    if (this.selectedFilters.size === 0) {
      this.showClear = false;
    }
  }

  onCheckboxChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.filterValues.require_action = true;
      this.selectedFilters.add('require_action');
      this.search();
    } else {
      this.clearSelectedOption('require_action');
    }
  }

  search(): void {
    this.searchParams = {};
    if (this.filterValues.search) {
      this.searchParams['search'] = `${this.filterValues.search}`;
    }

    if (this.filterValues.require_action) {
      this.searchParams['require_action'] = this.filterValues.require_action;
    }

    if (this.filterValues.organization) {
      this.searchParams[
        'organization_reference_id[eq]'
      ] = `${this.filterValues.organization}`;
    }

    if (this.filterValues.type) {
      this.searchParams['type'] = `${this.filterValues.type}`;
    }

    if (this.filterValues.dealership) {
      this.searchParams[
        'dealership_reference_id[eq]'
      ] = `${this.filterValues.dealership}`;
    }


    let store_searchParams = this.searchParams;
    //store_searchParams['search'] = '';
    this.localStorageService.setItem(
      'searchParamsInvoice',
      JSON.stringify(store_searchParams).replace('%', '').replace('%', '')
    );
    this.showClear = true;
    this.emitEvent();
  }

  searchTerms = new Subject<string>();

  clearFilter(event: Event): void {
    event.stopPropagation();
    this.filterValues = {
      search: '',
      require_action: null,
      type: null,
      organization: null,
      dealership: null,
    };

    this.searchText = '';
    this.searchTerms.next('');
    this.dealershipSearchTerm = '';
    this.organizationSearchTerm = '';

    this.selectedValue = '';
    this.displayTextType = '';
    this.displayTextDealership = '';

    this.clearableOrganization = false;
    this.clearableDealer = false;
    this.clearable = false;
    this.showClear = false;
    this.showDropdown = false;

    this.localStorageService.removeItem('searchParamsInvoice');
    this.requireActionCheckbox.nativeElement.checked = false;

    this.selectedFilters.clear();
    this.searchParams = {};
    this.updateButtonLabel();
    this.hideOptions();
    this.emitEvent();
  }

  hasActiveFilters(): boolean {
    return Object.keys(this.searchParams).length > 0;
  }

  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }
}
