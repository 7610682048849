<div class="row">
  <!-- <label for="reference" class="form-label">{{
    "pages.invoice.searchOrders" | translate
    }}</label> -->
  <div class="form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="broker_reference" class="form-label">{{
              "forms.inputs.reference" | translate
              }}</label>
            <input type="text" class="form-control" id="broker_reference" required formControlName="broker_reference"
              placeholder="{{
                'forms.inputs.reference' | translate
              }}" />
          </div>
        </div>
        <div class="col-3">
          <div class="mb-3">
            <label for="brand" class="form-label">{{ "forms.inputs.brand" | translate }}
              <span class="required">*</span></label>
            <select id="vehicle_brand_id" class="form-control" formControlName="vehicle_brand_id">
              <option value="" disabled selected>
                {{ "forms.inputs.brand" | translate }}
              </option>
              <option *ngFor="let brand of brands" [value]="brand.reference">
                {{ brand.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div class="mb-3">
            <label for="vehicle_status" class="form-label">{{
              "forms.inputs.status" | translate
              }}</label>
            <select id="vehicle_status" class="form-control" formControlName="vehicle_status">
              <option value="" disabled selected>
                {{ "forms.inputs.select_option" | translate }}
              </option>
              <option value="new" selected>
                {{ "forms.inputs.status_select.new" | translate }}
              </option>
              <option value="used">
                {{ "forms.inputs.status_select.used" | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="mb-3">
            <label for="start_date" class="form-label">{{
              "forms.inputs.start_date" | translate
              }}</label>

            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="start_date"
                formControlName="start_date" placeholder="{{
                  'forms.inputs.start_date' | translate
                }}" />
              <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
              <mat-datepicker #start_date></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
            <label for="end_date" class="form-label">{{
              "forms.inputs.end_date" | translate
              }}</label>

            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="end_date"
                formControlName="end_date" placeholder="{{
                  'forms.inputs.end_date' | translate
                }}" />
              <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
              <mat-datepicker #end_date></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
            <label for="stage" class="form-label">{{
              "forms.inputs.stage" | translate
              }}</label>
            <select id="stage" class="form-control" formControlName="stage">
              <option value="" disabled selected>
                {{ "forms.inputs.select_option" | translate }}
              </option>
              <option value="after-sales" selected>
                {{ "forms.inputs.status_select.contract_signed" | translate }}
              </option>
              <option value="delivered">
                {{ "forms.inputs.status_select.delivered" | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <div class="btn-izq">
              <button class="btn btn-hover" type="button" (click)="search()">
                {{ "buttons.searchInvoice" | translate }} <i class="icon-magnifier"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row linea"></div>
  </div>
</div>
