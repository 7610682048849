import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TitleResolver implements Resolve<void> {
  constructor(private titleService: Title, private translate: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    const titleKey = route.data['title'];
    if (titleKey) {
      return this.translate.get(titleKey).pipe(
        map(translatedTitle => {
          this.titleService.setTitle(translatedTitle);
        })
      );
    }
    return new Observable<void>(observer => observer.complete());
  }
}