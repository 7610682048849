<section class="bg-light py-3 py-md-5 vh-100">
  <div class="container">
    <div class="row justify-content-center login">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div class="card border border-light-subtle rounded-3 shadow-sm">
          <div class="card-body p-3 p-md-4 p-xl-5">
            <div class="login-logo">
              <img src="assets/images/logo-dark.png" class="img-fluid" alt="" height="22" />
            </div>
            <!-- <div>
              <h3 class="recover-title">{{
                "login.recover_account" | translate
                }}</h3>
            </div> -->
            <form *ngIf="!emailSent" [formGroup]="form">
              <div>
                <h4 class="email-recover">
                  {{ "login.email_to_recover" | translate}}
                </h4>
              </div>
              <div class="row gy-2 overflow-hidden login">
                <div class="col-12">
                  <label for="email" class="form-label">{{
                    "login.email" | translate
                    }}</label>
                  <div class="mb-3">
                    <input type="email" class="form-control" name="email" id="email" formControlName="email"
                      placeholder="{{ 'login.email' | translate }}" required />
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-column align-items-center my-3">
                    <button type="submit" class="btn btn-primary btn-login" (click)="onSubmit()">
                      {{ "login.sent_to_recover" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div *ngIf="emailSent" class="alert alert-success mt-3" role="alert">
              <div class="email_sent">
                {{ "login.email_sent" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
