<form [formGroup]="comissionForm">
  <div class="form-section">
    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="broker_commission_base" class="form-label">{{
            "forms.inputs.broker_commission_base" | translate
            }}
          </label>
          <div class="currencydiv">
            <input type="text" class="form-control" id="broker_commission_base" required
              formControlName="broker_commission_base" placeholder="{{
                'forms.inputs.broker_commission_base' | translate
              }}" />
            <span> EUR</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="broker_commission_tax" class="form-label">{{
            "forms.inputs.broker_commission_tax" | translate
            }}</label>
          <div class="currencydiv">
            <input type="text" class="form-control" id="broker_commission_tax" required
              formControlName="broker_commission_tax" placeholder="{{
                'forms.inputs.broker_commission_tax' | translate
              }}" />
            <span> EUR</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="broker_commission_total" class="form-label">{{
            "forms.inputs.broker_commission_total" | translate
            }}</label>
          <div class="currencydiv">
            <input type="text" class="form-control" id="broker_commission_total" required
              formControlName="broker_commission_total" placeholder="{{
                'forms.inputs.broker_commission_total' | translate
              }}" />
            <span> EUR</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
